<div #udenlandskStart></div>

<div class="row">
    <div class="col-12 col-md-4">
        <button *ngIf="!vis" type="submit" (click)="aabenLukSide(udenlandskStart)" class="btn btn-sm d-block w-100 btn-outline-primary text-start"
            aria-label="Åben formuler til indtastning af udenlandsk uddannelsessted">Tilføj udenlandsk uddannelsessted</button>
    </div>
</div>

<div [@foldudAmination]="foldudAmination">
    <main *ngIf="vis && tilstand !== tilstandEnum.Kvittering">
        <div class="row">
            <div class="col-12 pt-3">
                <div class="bg-primary-purple text-white p-2">Tilføj udenlandsk uddannelsessted </div>
            </div>
            <div class="col-12 pt-3">
                Læser du i Sverige eller Tyskland, kan du tilføje dit uddannelsessted her. 
                Uddannelsen skal være godkendt til SU.
                Din ansøgning om Ungdomskort skal godkendes i styrelsen.
            </div>
            <div class="col-12 pt-3">
                Når du er godkendt til Ungdomskort, kan du bestille og betale for
                dit Ungdomskort i trafikselskabernes bestillingssystem Mit Ungdomskort.
            </div>

            <app-adresse-form
                [adresse]="adresse"
                skjulLandekodeDK="true"
                submitKnapTekst="Indsend ansøgning"
                [sender]="tilstand === tilstandEnum.Sender"
                (annullerIndtastning$)="annullerUdland()"
                (gemIndtastning$)="gemUdland($event)"
                class="col-12"
            >
            </app-adresse-form>
        </div>
    </main>
</div>

<ng-template #kvittering>
    <div class="modal-header">
        <h3 class="modal-title">Kvittering - udenlandsk uddannelsessted</h3>
        <button type="button" class="close pull-right" aria-label="Luk kvittering" (click)="dialogRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Du kan gemme eller udskrive denne kvittering til senere brug. 
            Uddannelsen skal være godkendt til SU. 
            Din ansøgning om Ungdomskort skal godkendes i styrelsen.
            Når du er godkendt til Ungdomskort, kan du bestille og betale for dit Ungdomskort i trafikselskabernes
            bestillingssystem Mit Ungdomskort.
        </p>
        <div class="row">
            <div class=" col-12">
                Navn på uddannelsessted: {{ adresse.udlandUddSted.stedNavn }}
            </div>
            <div class="col-12">
                Adresse: {{ adresse | adresse }}
            </div>
            <div class="col-12">
                Min uddannelse begynder: {{ adresse.udlandUddSted.fra | langdato }}
            </div>
            <app-kvittering-filer [filer]="adresse.filer" class="row pb-3"></app-kvittering-filer>
            <app-gdpr-laes-mere class="col-12"></app-gdpr-laes-mere>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" attr.aria-label="Udskiv kvittering" (click)="udskriv()">
            Udskriv
        </button>
        <button type="button" class="btn btn-primary" attr.aria-label="Luk kvittering" (click)="lukKvittering()">
            Luk
        </button>
    </div>
</ng-template>

<ng-template #afslag>
    <div class="modal-header">
      <h3 class="modal-title">Afslag -  - udenlandsk uddannelsessted</h3>
      <button type="button" class="close pull-right" aria-label="Luk kvittering" (click)="dialogRefAfslag.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h4 class="py-3">Du er ikke berettiget til Ungdomskort ifm udenlandsk uddannelsessted</h4>
      <p>Du har søgt om godkendelse til Ungdomskort ifm udenlandsk uddannelsessted.</p>
      <p>
        Vi har afgjort at du ikke er berettiget til Ungdomskort ifm udenlandsk uddannelsessted, 
        fordi din daglige rejsetid er over 3½ timer hver vej.
      </p>
      <p>
        Vi henviser til §1 i bekendtgørelse om befordringsrabat til uddannelsessøgende i ungdomsuddannelser 
        eller til §2 i bekendtgørelse om befordringsrabat til studerende ved videregående uddannelser.
      </p>
      <p>
        På <a href="http://www.ungdomskort.dk" target="_blank">www.ungdomskort.dk</a> kan du læse om muligheden for at
        søge dispensation, hvis du kan dokumentere, at du reelt rejser dagligt over 3½ time hver vej.
      </p>
      <div class="row">
        <div class="col-12 text-center pt-2">
          <a href="#" (click)="lukKvitteringAfslag()" attr.aria-label="Luk kvittering"><small>Luk vinduet</small></a>
        </div>
      </div>
    </div>
  </ng-template>

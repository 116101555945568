<div #ekstraStart></div>

<div class="row mb-4">
  <div class="col-12 col-md-4">
    <button *ngIf="!vis" type="submit" (click)="aabenLukSide(ekstraStart)" class="btn btn-sm d-block w-100 btn-outline-primary text-start"
      aria-label="Åben formuler til indtastning af nyt uddannelsessted">Tilføj uddannelsessted</button>
  </div>
</div>

<div [@foldudAmination]="foldudAmination">
  <main *ngIf="vis && tilstand !== tilstandEnum.Kvittering">
    <div class="row">
      <div class="col-12">
        <div class="bg-primary-purple text-white p-2">Tilføj endnu et uddannelsessted</div>
      </div>
      <div class="col-12 pt-3">
        Foregår din undervisning på to eller flere adresser, skal du godkendes til flere strækninger. Du kan tilføje uddannelsessteder nedenfor. 
        Når du har tastet "Godkend", skal dit uddannelsessted bekræfte, at du modtager undervisning på flere forskellige adresser.
      </div>
      <div class="col-12 pt-3">
        Når de har gjort det, får du en kvittering som Digital Post via borger.dk. 
        Først derefter kan du købe dit Ungdomskort i trafikselskabernes selvbetjeningssystem Mit Ungdomskort.
      </div>

      <app-adresse-form
        [adresse]="adresse"
        submitKnapTekst="Indsend ansøgning"
        [sender]="tilstand === tilstandEnum.Sender"
        (annullerIndtastning$)="annullerUddsted()"
        (gemIndtastning$)="gemUddsted($event)"
        class="col-12"
      >
      </app-adresse-form>
    </div>
  </main>
</div>

<ng-template #kvittering>
  <div class="modal-header">
    <h3 class="modal-title">Kvittering - endnu et uddannelsessted</h3>
    <button type="button" class="close pull-right" aria-label="Luk kvittering" (click)="dialogRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Du kan gemme eller udskrive denne kvittering til senere brug. 
      Dit uddannelsessted skal bekræfte, at du er modtager undervisning på flere forskellige adresser. 
      Når de har gjort det, får du en kvittering som Digital Post via borger.dk. 
      Først derefter kan du købe dit Ungdomskort i trafikselskabernes selvbetjeningssystem Mit Ungdomskort.
    </p>
    <div class="row">
      <div class="col-12">
        Navn på uddannelsessted: {{ adresse.ekstraUddsted.stedNavn }}
      </div>
      <div class="col-12">
        Adresse: {{ adresse | adresse }}
      </div>
      <div class="col-6 col-lg-3">
        Fra dato: {{ adresse.ekstraUddsted.fra | langdato }}
      </div>
      <div class="col-6 col-lg-3">
        Til dato: {{ adresse.ekstraUddsted.til | langdato }}
      </div>
      <div class="w-100 d-none d-lg-block"></div>
      <app-kvittering-filer [filer]="adresse.filer" class="row pb-3"></app-kvittering-filer>
      <app-gdpr-laes-mere class="col-12"></app-gdpr-laes-mere>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" attr.aria-label="Udskriv kvittering" (click)="udskriv()">
      Udskriv
    </button>
    <button type="button" class="btn btn-primary" attr.aria-label="Luk kvittering" (click)="lukKvittering()">
      Luk
    </button>
  </div>
</ng-template>

<ng-template #afslag>
  <div class="modal-header">
    <h3 class="modal-title">Afslag - endnu et uddannelsessted</h3>
    <button type="button" class="close pull-right" aria-label="Luk kvittering" (click)="dialogRefAfslag.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4 class="py-3">Du er ikke berettiget til Ungdomskort ifm endnu et uddannelsessted</h4>
    <p>Du har søgt om godkendelse til Ungdomskort ifm endnu et uddannelsessted.</p>
    <p>Vi har afgjort at du ikke er berettiget til Ungdomskort ifm endnu et uddannelsessted, fordi din daglige rejsetid er over 3½ timer hver vej.</p>
    <p>Vi henviser til §1 i bekendtgørelse om befordringsrabat til uddannelsessøgende i ungdomsuddannelser eller til §2
      i bekendtgørelse om befordringsrabat til studerende ved videregående uddannelser.</p>
    <p>På <a href="http://www.ungdomskort.dk" target="_blank">www.ungdomskort.dk</a> kan du læse om muligheden for at
      søge dispensation, hvis du kan dokumentere, at du reelt rejser dagligt over 3½ time hver vej.</p>
    <div class="row">
      <div class="col-12 text-center pt-2">
        <a href="#" (click)="lukKvitteringAfslag()" attr.aria-label="Luk kvittering"><small>Luk vinduet</small></a>
      </div>
    </div>
  </div>
</ng-template>

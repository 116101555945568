<div>
  <div class="row" [ngClass]="{'bg-light text-muted': !rejsestraekning.kanSlettes}">
    <div class="col-12 col-lg-4">
      <div class="row">
        <div class="col-12">{{ rejsestraekning.lokation.navn }}</div>
        <div class="col-12 pt-2">{{ rejsestraekning.lokation.adresse | adresse:1 }}</div>
        <div class="col-12">{{ rejsestraekning.lokation.adresse | adresse:2 }}</div>
        <div class="col-12">{{ rejsestraekning.lokation.adresse | adresse:3 }}</div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div *ngIf="rejsestraekning.status | status" class="ufm-orange text-dark text-center">
        {{ rejsestraekning.status | status }}
      </div>
    </div>
    <div class="col-12 col-lg-4 small text-end">
      For perioden {{ rejsestraekning.fra | langdato }} - {{ rejsestraekning.til | langdato}}
    </div>
    <div class="col-4 pt-2">
      <div class="float-start">
        <button
          *ngIf="rejsestraekning.kanSlettes"
          type="button"
          class="btn btn-sm btn-outline-primary"
          [disabled]="sender"
          aria-label="Slet rejsestrækning"
          (click)="visSletDialog(template)"
        >
          Slet
        </button>
      </div>
    </div>
    <div class="col-4 pt-2 text-center">
      <button *ngIf="rejsestraekning.status === 'AFVENTER_UDDANNELSESSTEDET'"
          type="button"
          class="btn btn-sm btn-outline-primary"
          [disabled]="visBerig"
          (click)="aabenBeritAnsoegning()"
        >
        Tilføj dokumenter
      </button> 
    </div>
    <div class="col-4 pt-2">
      <div class="float-end">
        <a *ngIf="rejsestraekning.status === 'GODKENDT'" class="ms-2 btn btn-primary text-white btn-sm" href="{{trin2Url}}"
          target="_blank" (click)="sendTrin2UrlTilGA()" role="button" aria-label="Bestil ungdomskort hos trafikselskaberne. Åbnes i et nyt faneblad">Bestil
          ungdomskort</a>
      </div>
    </div>
    <div *ngIf="rejsestraekning.status === 'AFVENTER_UDDANNELSESSTEDET' && visBerig" class="col-12">
      <app-berig-ansoegning [rejsestraekning]="rejsestraekning" [vis]="foludBerig" (afslutIndtastning$)="visBerig=false">
      </app-berig-ansoegning>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title">Slet rejsestrækning</h4>
    <button type="button" class="close pull-right" aria-label="Fortryd slet rejsestrækning" (click)="dialogRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>
      Slet din <span *ngIf="rejsestraekning.status !== 'GODKENDT'">ikke godkendte</span>
      rejsestrækning til {{ rejsestraekning.lokation.navn }} {{rejsestraekning.lokation.adresse | adresse}}? 
      ({{ rejsestraekning.status | status }}).
    </p>
    <div *ngIf="dialogType === dialogTypeEnum.GodkendtPrimaerMedSekundaer">
      <p *ngFor="let sekundaerRejsestraekning of sekundaereRejsestraekningerDerSlettes">
        Din <span *ngIf="rejsestraekning.status !== 'GODKENDT'">ikke godkendte</span>
        rejsestrækning til {{ sekundaerRejsestraekning.lokation.navn }}
        {{sekundaerRejsestraekning.lokation.adresse | adresse}}? 
        ({{ sekundaerRejsestraekning.status | status}}) bliver også slettet!
      </p>
    </div>
    <p *ngIf="rejsestraekning.status === 'GODKENDT'">
      Dit Ungdomskort til ovenstående rejsestrækning vil ikke blive fornyet.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" aria-label="Fortryd slet rejsestrækning" (click)="dialogSvar(false)">
      Annuller
    </button>
    <button 
      type="button" 
      class="btn btn-primary" 
      attr.aria-label="slet rejsestrækning til {{ rejsestraekning.lokation.navn }} {{rejsestraekning.lokation.adresse | adresse}}? ({{ rejsestraekning.status | status}}). {{dialogType === dialogTypeEnum.GodkendtPrimaerMedSekundaer ? 'Dit rejsekort til ikke blive fornyet. Du bør få hele sidens indhold oplæst inden du trykker Slet' : ''}}"
      (click)="dialogSvar(true)">
      Slet
    </button>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Profil } from './profil';
import { Flytning } from '../../fælles-utils/adresse/flytning';
import { Kommune } from '../../fælles-utils/adresse/kommune';
import { Adresse } from '../../fælles-utils/adresse/adresse';
import { Fil } from '../../fælles-utils/adresse/fil';
import { ApiFormService } from '../../fælles-utils/adresse/api-form.service';

@Injectable()
export class ProfilService {

  profil$ = new BehaviorSubject<Profil>(null);

  constructor(private http: HttpClient, private apiFormService: ApiFormService) { }

  public getProfil(): Observable<Profil> {
    // opret Profil instance udfra json response så instanceof type tjek er muligt
    return this.http.get(environment.ukApi + '/profil')
      .pipe(
        map((profil) => new Profil(profil)),
        tap((profil) => this.profil$.next(profil))
      );
  }

  hentProfil(): void {
    this.getProfil().subscribe();
  }

  hentKommuner(): Observable<Kommune[]> {
    return this.http.get<Kommune[]>(environment.ukApi + '/kommuner');
  }

  meldFlytning(flytning: Flytning): Observable<void> {
    // todo: før send
    // 1. lav json ansoegning som flytning objekt, men uden filer i adresse.
    const formData: FormData = this.apiFormService.ansoegningFormData(flytning);
    return this.http
      .put<void>(environment.ukApi + '/profil/flytning', formData)
      .pipe(tap(() => this.hentProfil()));
  }

}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable } from 'rxjs';

import { ProfilService } from './profil.service';
import { ApiFormService } from '../../fælles-utils/adresse/api-form.service';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';
import { UdskrivService } from '../../fælles-utils/udskriv-kvittering/udskriv.service';

import { Profil } from './profil';
import { AdresseForm } from '../../fælles-utils/adresse/adresse-form/adresse-form';
import { Flytning } from '../../fælles-utils/adresse/flytning';
import { Adresse } from '../../fælles-utils/adresse/adresse';
import { Tilstand } from '../../fælles-utils/tilstand-ui/tilstand.enum';
import { Kommune } from '../../fælles-utils/adresse/kommune';
import { UfmBackendtid2Service } from '@ufmit/ufm-backendtid2';
import { Moment } from 'moment';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';
import { RejsestraekningService } from '../rejsestraekninger/rejsestraekning.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  tilstand: Tilstand;
  profil: Profil;
  adresse: AdresseForm;
  kommuner: Kommune[];
  flytning: Flytning;
  harMeldtFlytning: boolean;
  tilstandEnum = Tilstand;
  dialogRef: BsModalRef;
  @ViewChild('kvittering', { static: true }) templateRef: TemplateRef<any>;
  hentet: Moment;

  constructor(
    private profilService: ProfilService,
    private backendtidService: UfmBackendtid2Service,
    private apiFormService: ApiFormService,
    private userTracking: UfmUserTrackingService,
    private modalService: BsModalService,
    private udskrivService: UdskrivService,
    private ufmFuldmagtService: UfmFuldmagtService,
    private rejsestraekningService: RejsestraekningService
  ) {}

  ngOnInit(): void {
    this.tilstand = Tilstand.Indlaeser;
    this.backendtidService.hentBackendtid();
    this.backendtidService.backendtid$.subscribe((backendTid) => {
      if (!backendTid) {
        this.hentet = null;
        return;
      }
      this.hentet = backendTid.clone();
    });
    this.profilService.profil$.subscribe((profil) => {
      if (!profil) {
        return;
      }
      this.tilstand = Tilstand.Viser;
      this.profil = profil;
      this.harMeldtFlytning = !!profil.flytning;
    });
    this.ufmFuldmagtService.skiftetFuldmagtsBruger$.subscribe((skiftet) => {
      if (skiftet) {
        this.profilService.hentProfil();
      }
    });
  }

  visForm(): void {
    this.userTracking.sendUrl('/flytning');    
    this.profilService.hentKommuner().subscribe((kommuner) => {
      this.kommuner = kommuner;

      if (!this.profil.flytning) {
        this.profil.flytning = new Flytning({ kommunekode: '' });
        this.profil.flytning.flyttedato = this.hentet.toDate();
      }
      if (!this.profil.flytning.adresse) {
        this.profil.flytning.adresse = new Adresse({ landekode: 'DK' });
      }
      this.adresse = this.apiFormService.initialiserForm(this.profil.flytning);
      this.tilstand = Tilstand.Indtaster;
    });
  }

  annullerFlytning(): void {
    this.userTracking.sendUrl('/flytning/annuller');
    if (!this.harMeldtFlytning) {
      this.profil.flytning = null;
    }
    this.tilstand = Tilstand.Viser;
  }

  gemFlytning(adresse): void {
    this.tilstand = Tilstand.Sender;
    this.flytning = this.apiFormService.getFlytning(adresse);

    this.profilService.meldFlytning(this.flytning).subscribe({
      next: () => {
        this.tilstand = Tilstand.Viser;
        this.backendtidService.hentBackendtid();
        this.visKvittering();
      },
      error: () => {
        this.userTracking.sendUrl('/flytning/fejl');
        this.tilstand = Tilstand.Indtaster;
      }
    });
  }

  visKvittering() {
    this.dialogRef = this.modalService.show(this.templateRef);
  }

  udskriv() {
    this.udskrivService.udskriv();
  }

  lukKvittering(): void {
    this.dialogRef.hide();
    if(this.flytning.adresse.landekode === 'DK') {
      this.userTracking.sendUrl('/flytningDa/kvittering/luk');
    } else {  
      this.userTracking.sendUrl('/flytningUdl/kvittering/luk');
    }
  }

}

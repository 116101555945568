import * as moment from 'moment/moment';

import { Flytning } from '../flytning';
import { Praktik } from '../../../forretnings-sider/praktikplads/praktik';
import { EkstraUddsted } from '../../../forretnings-sider/ekstra-uddannelsessted/ekstra-uddsted';
import { UdlandUddsted } from '../../../forretnings-sider/udenlandsk-uddannelsessted/udland-uddsted';
import { Fil } from '../fil';

export class AdresseForm {
  public landekode: string;
  public postnr: string;
  public bynavn: string;
  public vejnavn: string;
  public husnr: string;
  public etage: string;
  public sidedoer: string;
  public flytning: {
    conavn: string;
    flyttedato: Date;
    adressebeskyttelse: Boolean;
    skolehjem: Boolean;
    skolehjemNavn: String;
    kommunekode: number;
  };
  public praktik: {
    stedNavn: string;
    fra: Date;
    til: Date;
    rejsetid: number;
    troOgLoveRejsetid: string;
  };
  public ekstraUddsted: {
    stedNavn: string;
    fra: Date;
    til: Date;
    rejsetid: number;
    troOgLoveRejsetid: string;
  };
  public udlandUddSted: {
    stedNavn: string;
    fra: Date;
    rejsetid: number;
    troOgLoveRejsetid: string;
  };
  public filer: Fil[];

  constructor(adresseInfo: Flytning | Praktik | EkstraUddsted | UdlandUddsted) {
    this.landekode = adresseInfo.adresse.landekode;
    if (this.landekode === 'DK') {
      this.postnr = adresseInfo.adresse.postnr;
    } else {
      this.postnr = adresseInfo.adresse.zipcode;
    }
    this.bynavn = adresseInfo.adresse.bynavn;
    this.vejnavn = adresseInfo.adresse.vejnavn;
    this.husnr = adresseInfo.adresse.husnr;
    this.etage = adresseInfo.adresse.etage;
    this.sidedoer = adresseInfo.adresse.sidedoer;
    if (adresseInfo instanceof Flytning) {
      this.flytning = {
        conavn: adresseInfo.conavn,
        flyttedato: moment.utc(adresseInfo.flyttedato).toDate(),
        adressebeskyttelse: adresseInfo.adressebeskyttelse,
        skolehjem: adresseInfo.skolehjem,
        skolehjemNavn: adresseInfo.skolehjemNavn,
        kommunekode: adresseInfo.kommunekode
      };
    } else if (adresseInfo instanceof Praktik) {
      this.praktik = {
        stedNavn: adresseInfo.stedNavn,
        fra: adresseInfo.fra ? moment.utc(adresseInfo.fra).toDate() : null,
        til: adresseInfo.til ? moment.utc(adresseInfo.til).toDate() : null,
        rejsetid: adresseInfo.rejsetid,
        troOgLoveRejsetid: adresseInfo.troOgLoveRejsetid
      };
    } else if (adresseInfo instanceof EkstraUddsted) {
      this.ekstraUddsted = {
        stedNavn: adresseInfo.stedNavn,
        fra: adresseInfo.fra ? moment.utc(adresseInfo.fra).toDate() : null,
        til: adresseInfo.til ? moment.utc(adresseInfo.til).toDate() : null,
        rejsetid: adresseInfo.rejsetid,
        troOgLoveRejsetid: adresseInfo.troOgLoveRejsetid
      };
    } else {
      this.udlandUddSted = {
        stedNavn: adresseInfo.stedNavn,
        fra: adresseInfo.fra ? moment.utc(adresseInfo.fra).toDate() : null,
        rejsetid: adresseInfo.rejsetid,
        troOgLoveRejsetid: adresseInfo.troOgLoveRejsetid
      };
    }
  }
}

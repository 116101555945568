import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rejsetid-laes-mere',
  templateUrl: './rejsetid-laes-mere.component.html',
  styleUrls: ['./rejsetid-laes-mere.component.scss']
})
export class RejsetidLaesMereComponent {

  constructor() { }

}

<div class="container bg-dark">
  <footer class="footer mt-5">
    <div class="py-3">
      <div class="text-white mt-4">
        Uddannelses- og Forskningsstyrelsen
      </div>
      <div>
        <a class="text-white" href="http://www.ungdomskort.dk/" target="_blank" (click)="sendUngdomskortUrlTilGA()"
          aria-label="Gå til ungdomskort.dk. Åbner i nyt faneblad"><u>www.ungdomskort.dk</u></a>
      </div>
      <div>
        <a class="text-white" href="{{trin2Url}}" target="_blank" (click)="sendTrin2UrlTilGA()"
          aria-label="Gå til mitungdomskort.dk. Trafikselskabernes fælles Ungdomskort-bestilling. Åbner i nyt faneblad">
          <u>Gå til Mit Ungdomskort (Trin 2)</u>
        </a>
      </div>
      <div class="mb-4">
        <a class="text-white" href="https://was.digst.dk/ungdomskort-uddannelsesstoette-dk" target="_blank"
          aria-label="Gå til ungdomskort.dk. Åbner i nyt faneblad"><u>Tilgængelighedserklæring</u>
        </a>
      </div>      
    </div>
  </footer>
</div>

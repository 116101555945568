import { Pipe, PipeTransform } from '@angular/core';

import { Adresse } from '../adresse';
import { AdresseForm } from '../adresse-form/adresse-form';

@Pipe({
  name: 'postnr'
})
export class PostnrPipe implements PipeTransform {
  
  transform(adresse: Adresse | AdresseForm): string {
    if (adresse instanceof AdresseForm) {
      // i AdresseForm anvendes postnr for både danske og udenlandske adresser
      return adresse.postnr;
    } else {
      // i Adresse anvendes postnr for danske adresser og zipcode for udenlandske adresser
      if (adresse.landekode === 'DK') {
        return adresse.postnr;
      } else {
        return adresse.zipcode;
      }
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-foldud',
  templateUrl: './fold-ud.component.html',
  styleUrls: ['./fold-ud.component.scss']
})
export class FoldUdComponent implements OnInit {

  @Input() overskrift: string;
  @Input() style: string;

  vis = false;

  constructor() { }

  ngOnInit() {
    // todo: forretningen skal vælge hvordan fold ud/fold sammen hjælpe tekster skal styles.
    // 1) link: simpel angular vis/skjul tekst funktionalitet med "Læs mere" i link tekst
    // 2) accordion: simpel angular vis/skjul funktionalitet. accordion style fra ungdomskort.dk
    // 3) ngx-bootstrap collapse: simpel bootstrap knap
    if (['link', 'accordion', 'collapse'].indexOf(this.style) === -1) {
      this.style = 'link';
    }
  }

}

import { Injectable, Injector, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Moment } from 'moment';
import { HttpClient } from '@angular/common/http';
import { map, tap, filter, mergeMap, distinctUntilChanged } from 'rxjs/operators';
import * as moment from 'moment/moment';

import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';
import { UfmBackendtid2ConfigService } from './ufm-backendtid2-config.service';

@Injectable({
  providedIn: 'root'
})
export class UfmBackendtid2Service {

  backendtid$: BehaviorSubject<Moment> = new BehaviorSubject<Moment>(null);
  private backendDato!: Moment | null;

  constructor(
    private http: HttpClient,
    private ufmBackendtid2ConfigService: UfmBackendtid2ConfigService,
    private ufmFuldmagtService: UfmFuldmagtService) {
  }

  private konverterTilUTCDato(backendtid: Moment): Moment {
    const dato: Date = backendtid.toDate();
    return moment.utc([dato.getFullYear(), dato.getMonth(), dato.getDate()]);
  }

  private getBackendtid(): Observable<string> {
    return this.http.get(this.ufmBackendtid2ConfigService.getBackendtidRequestUrl(), { responseType: 'text' }).pipe(
      tap(response => {
        const backendtid: moment.Moment = moment(response);
        const nu: moment.Moment = moment.utc();
        if (nu.isSame(backendtid, 'date')) {
          this.backendDato = null;
        } else {
          this.backendDato = this.konverterTilUTCDato(backendtid);
        }
        this.backendtid$.next(moment(response));
      })
    );
  }

  private hentBackendtidVoid(): void {
    this.getBackendtid().subscribe();
  }
  private hentBackendtidObservable(): Observable<boolean> {
    return this.getBackendtid().pipe(
      map(_ => true)
    );
  }
  hentBackendtid(returnerObservable?: boolean): Observable<boolean> {
    if (returnerObservable) {
      return this.hentBackendtidObservable();
    } else {
      this.hentBackendtidVoid();
    }
  }

  erBackendDatoSat(): boolean {
    return this.backendDato ? true : false;
  }

  dagsdato(): Moment {
    if (this.erBackendDatoSat()) {
      return moment.utc(this.backendDato);
    } else {
      return moment.utc();
    }
  }

  nu(): Moment {
    if (this.erBackendDatoSat()) {
      const nu: Moment = moment.utc();
      const dagsdato: Moment = this.konverterTilUTCDato(nu);
      const diff: number = moment.utc(nu).diff(moment.utc(dagsdato));
      return moment.utc(this.backendDato).add(diff, 'milliseconds');
    } else {
      return moment.utc();
    }
  }

  hentBackendtidForAgerendeBruger(): Observable<Moment> {
    return this.ufmFuldmagtService.fuldmagtsBruger$.pipe(
      map(ufmFuldmagtBruger => {
        if (ufmFuldmagtBruger && ufmFuldmagtBruger.agerendeCpr) {
          return ufmFuldmagtBruger.agerendeCpr;
        } else {
          return null;
        }
      }),
      distinctUntilChanged(),
      filter(agerendeCpr => agerendeCpr ? true : false),
      mergeMap(() => this.getBackendtid()),
      map(() => this.backendtid$.value)
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'langdato'
})
export class LangdatoPipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: any): any {
    return this.datePipe.transform(value, 'longDate', 'UTC');
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { Adresse } from '../adresse';

@Component({
  selector: 'app-vis-adresse',
  templateUrl: './vis-adresse.component.html',
  styleUrls: ['./vis-adresse.component.scss']
})
export class VisAdresseComponent {

  @Input() flyttedato: Date;
  @Input() conavn: string;
  @Input() adresse: Adresse;

}

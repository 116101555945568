import { Fil } from "./fil";

export class Adresse {
  public landekode: string;
  public postnr: string;
  public bynavn: string;
  public vejnavn: string;
  public husnr: string;
  public etage: string;
  public sidedoer: string;
  public zipcode: string;
  public filer: Fil[];

  constructor(json: any) {
    this.landekode = json.landekode;
    this.postnr = json.postnr;
    this.bynavn = json.bynavn;
    this.vejnavn = json.vejnavn;
    this.husnr = json.husnr;
    this.etage = json.etage;
    this.sidedoer = json.sidedoer;
    this.zipcode = json.zipcode;
    this.filer = json.filer;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rejsetid',
  templateUrl: './rejsetid.component.html',
  styleUrls: ['./rejsetid.component.scss']
})
export class RejsetidComponent {

  constructor() { }

}

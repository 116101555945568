<div class="container p-0">
  <nav class="navbar navbar-expand-md navbar-dark bg-dark">

    <a class="navbar-brand rounded pt-0 pb-0 ms-3" routerLink="/" routerLinkActive="active">
      <img src="assets/img/logo_ufm_dk_hvid_72_dpi_web_480.png" height="50" class="d-inline-block align-top" alt="Link til ungdomskort selvbetjening forside">
    </a>

    <button class="navbar-toggler me-3" type="button" data-bs-toggle="collapse" (click)="isCollapsed = !isCollapsed"
      aria-expanded="false" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" [attr.aria-expanded]="!isCollapsed"
      aria-label="Menu knap">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse ms-3" id="navbarNavDropdown" [collapse]="isCollapsed">
      <ul class="navbar-nav me-auto"></ul>
      <ul class="navbar-nav">
        <li *ngIf="erLoggetPaa" class="nav-item">
          <span class="nav-text pe-5 text-white"><b>{{ brugernavn }}</b></span>
        </li>
      </ul>
      <a *ngIf="!erLoggetPaa" class="text-white text-end text-underline-hover me-3" href="#" (click)="logPaa()">Log på</a>
      <!-- <a *ngIf="erLoggetPaa" class="text-white text-end" href="#" (click)="devLogin()">DevLogin</a> -->
      <a *ngIf="erLoggetPaa" class="text-white text-end text-underline-hover me-3" href="#" (click)="logAf()">Log af</a>     
    </div>
  </nav>
</div>
import { Adresse } from './adresse';

export class Flytning {
  public adresse: Adresse;
  public conavn: string;
  public flyttedato: Date;
  public adressebeskyttelse: boolean;
  public skolehjem: boolean;
  public skolehjemNavn: string;
  public kommunekode: number;

  constructor(json: any) {
    if (json.adresse) {
      this.adresse = new Adresse(json.adresse);
    }
    this.conavn = json.conavn;
    this.flyttedato = json.flyttedato;
    this.adressebeskyttelse = json.adressebeskyttelse;
    this.skolehjem = json.skolehjem;
    this.skolehjemNavn = json.skolehjemNavn;
    this.kommunekode = json.kommunekode;
  }
}

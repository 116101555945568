import { Injectable, Injector, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UfmBackendtid2Config, UFM_BACKENDTID2_CONFIG } from './ufm-backendtid2.config';
import { UFM_PROPERTIES_CONFIG, UfmPropertiesConfig } from '@ufmit/ufm-properties';

@Injectable({
  providedIn: 'root'
})
export class UfmBackendtid2ConfigService {

  public backendtid2KonfigurationOpdateret$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private ufmBackendtid2Config: UfmBackendtid2Config = {};
  private ufmPropertiesConfig: UfmPropertiesConfig = {};

  constructor(
    @Inject(Injector) private injector: Injector) {
    // hent ufmBackendtidConfig (apiPropertyNavn)
    try {
      this.ufmBackendtid2Config = this.injector.get(UFM_BACKENDTID2_CONFIG);
    } catch (e) { }
    // hent ufmPropertiesConfig (backendtidRequestUrl, sessionRequestUrl, frontendClientLogRequestUrl)
    try {
      this.ufmPropertiesConfig = this.injector.get(UFM_PROPERTIES_CONFIG);
    } catch (e) { }
  }

  // dynamisk config getter
  public getApiPropertyNavn(): string {
    if (this.ufmBackendtid2Config.environment && this.ufmBackendtid2Config.environment.apiPropertyNavn) {
      return this.ufmBackendtid2Config.environment.apiPropertyNavn;
    } else {
      return 'api';
    }
  }
  public getApi(): string {
    const apiPropertyNavn = this.getApiPropertyNavn();
    const environment: any = this.ufmPropertiesConfig.environment || {};
    const api: string = environment[apiPropertyNavn] ? environment[apiPropertyNavn] : '/api';
    return api;
  }
  public getEndpoint(): string {
    if (this.ufmBackendtid2Config.environment && this.ufmBackendtid2Config.environment.endpoint) {
      return this.ufmBackendtid2Config.environment.endpoint;
    } else {
      return '/profil';
    }
  }
  public getRequestParams(): string {
    const environment: any = this.ufmPropertiesConfig.environment || {};
    if (environment.version) {
      return '?feVersion=' + environment.version;
    } else {
      return '';
    }
  }
  public getBackendtidRequestUrl(): string {
    let endpoint = '/backendtid';
    if (this.ufmBackendtid2Config && this.ufmBackendtid2Config.environment) {
      endpoint = this.ufmBackendtid2Config.environment.endpoint || '/backendtid';
    }
    const backendtidRequestUrl: string = this.getApi() + endpoint + this.getRequestParams();
    return backendtidRequestUrl;
  }
  public getSessionRequestUrl(): string {
    const sessionRequestUrl: string = this.getApi() + '/session';
    return sessionRequestUrl;
  }
  public getFrontendClientLogRequestUrl(): string {
    const frontendClientLogRequestUrl: string = this.getApi() + '/frontend-client-log';
    return frontendClientLogRequestUrl;
  }

  public genindlaesOpdateretKonfiguration(): void {
    this.backendtid2KonfigurationOpdateret$.next(true);
  }

  public getBackendtid2Config(): UfmBackendtid2Config {
    return {
      environment: {
        apiPropertyNavn: this.getApiPropertyNavn(),
        endpoint: this.getEndpoint()
      },
      tekster: this.ufmBackendtid2Config.tekster || {}
    };
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'type'
})
export class TypePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'PRIMAER': {
        return 'Primær';
      }
      case 'SEKUNDAER': {
        return 'Sekundær';
      }
      default: {
        return value;
      }
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, NavigationStart, NavigationEnd } from '@angular/router';

import { GdprComponent } from './side-struktur/gdpr/gdpr.component';
import { GdprGuard } from './side-struktur/gdpr/guards/gdpr.guard';
import { IkkeGdprGuard } from './side-struktur/gdpr/guards/ikke-gdpr.guard';
import { LoggetPaaGuard } from './fælles-utils/log-paa/logget-paa.guard';
import { LogAfComponent } from './side-struktur/log-af/log-af.component';
import { ForsideComponent } from './forretnings-sider/forside/forside.component';
import { FuldmagtGuard } from './forretnings-sider/fuldmagt/fuldmagt.guard';
import { UfmSessionTimeoutComponent, UfmSessionDemoAuthComponent } from '@ufmit/ufm-session';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

const routes: Routes = [
  { path: '', component: ForsideComponent, canActivate: [LoggetPaaGuard, FuldmagtGuard, GdprGuard] },
  { path: 'demo-auth', component: UfmSessionDemoAuthComponent },
  { path: 'gdpr', component: GdprComponent, canActivate: [LoggetPaaGuard, IkkeGdprGuard] },
  { path: 'logaf', component: LogAfComponent},
  { path: 'timeout', component: UfmSessionTimeoutComponent},
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(private router: Router, private userTracking: UfmUserTrackingService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (['/tekniskeproblemer', '/timeout'].indexOf(event.url) > -1 && !router.navigated) {
          // retur til forside ved reload
          router.navigate(['/']);
          return;
        }
      }
      if (event instanceof NavigationEnd) {
        this.userTracking.sendUrl(event.urlAfterRedirects);
      }
    });
  }
}

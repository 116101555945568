import { NgModule, Optional, ModuleWithProviders, SkipSelf } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';

import { UFM_BACKENDTID2_CONFIG, UfmBackendtid2Config } from './ufm-backendtid2.config';
import { UfmBackendtid2Component } from './ufm-backendtid2.component';

@NgModule({
  declarations: [UfmBackendtid2Component],
  providers: [DatePipe],
  imports: [CommonModule],
  exports: [UfmBackendtid2Component]
})
export class UfmBackendtid2Module {
  constructor(@Optional() @SkipSelf() parentModule?: UfmBackendtid2Module) {
    if (parentModule) {
      throw new Error(
        'UfmBackendtid2Module is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: UfmBackendtid2Config): ModuleWithProviders<UfmBackendtid2Module> {
    return {
      ngModule: UfmBackendtid2Module,
      providers: [
        { provide: UFM_BACKENDTID2_CONFIG, useValue: config }
      ]
    };
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { interval } from 'rxjs';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class UdskrivService {

  private viserKvittering$ = new BehaviorSubject<boolean>(false);
  noprint$ = this.viserKvittering$.pipe(map((viserKvittering) => (viserKvittering ? 'no-print' : null)));

  constructor(private modalService: BsModalService) {
    interval(250).subscribe(
      () => { this.viserKvittering$.next(this.modalService.getModalsCount() > 0); });
  }

  udskriv(): void {
    _window().print();
  }
}

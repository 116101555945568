<span *ngIf="style === 'link'">
  <span *ngIf="!vis" (click)="vis = !vis">
    {{ overskrift }} <a href="#" (click)="(false)">... læs mere</a>
  </span>
</span>
<span *ngIf="style === 'accordion'">
  <div class="accordion">
    <h2 (click)="vis = !vis" [class.current]="vis">
      {{ overskrift }}<span class="accordion-menu-down"></span>
    </h2>
  </div>
</span>
<span *ngIf="style === 'collapse'">
  <button type="button" class="btn btn-primary" (click)="vis = !vis" [attr.aria-expanded]="!vis"
    aria-controls="collapseBasic">{{ overskrift }} ... Læs mere
  </button>
</span>
<div *ngIf="vis" (click)="style !== 'collapse' && (vis = !vis)">
  <ng-content></ng-content>
</div>
import { UddannelsesLokation } from '../../fælles-utils/adresse/uddannelses-lokation';

export class Rejsestraekning {
  readonly id: number;
  readonly ansoegningSekvensNr: number;
  readonly fra: Date;
  readonly til: Date;
  readonly type: string;
  readonly status: string;
  readonly lokation: UddannelsesLokation;
  readonly kanSlettes: boolean;
}

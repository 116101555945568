import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { timer } from 'rxjs';

import { Tilstand } from '../../fælles-utils/tilstand-ui/tilstand.enum';
import { UdlandUddsted } from './udland-uddsted';
import { AdresseForm } from '../../fælles-utils/adresse/adresse-form/adresse-form';
import { Adresse } from '../../fælles-utils/adresse/adresse';

import { ApiFormService } from '../../fælles-utils/adresse/api-form.service';
import { RejsestraekningService } from '../rejsestraekninger/rejsestraekning.service';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';
import { UdskrivService } from '../../fælles-utils/udskriv-kvittering/udskriv.service';
import { foldudAmination } from '../../fælles-utils/animationer/animationer';
import { UfmBackendtid2Service } from '@ufmit/ufm-backendtid2';

const foldud = 'foldud';
const foldsammen = 'foldsammen';

@Component({
  selector: 'app-udland-uddsted',
  templateUrl: './udenlandsk-uddannelsessted.component.html',
  styleUrls: ['./udenlandsk-uddannelsessted.component.scss'],
  animations: [foldudAmination]
})
export class UdenlandskUddannelsesstedComponent implements OnInit {

  tilstand: Tilstand;
  adresse: AdresseForm;
  tilstandEnum = Tilstand;
  dialogRef: BsModalRef;
  vis: boolean;
  foldudAmination = foldsammen;
  @ViewChild('kvittering', { static: true }) templateRef: TemplateRef<any>;
  dialogRefAfslag: BsModalRef;
  @ViewChild('afslag', { static: true }) templateRefAfslag: TemplateRef<any>;
  rejsetid: string;
  troOgLoveRejsetid: string;

  constructor(
    private rejsestraekningService: RejsestraekningService,
    private backendtidService: UfmBackendtid2Service,
    private apiFormService: ApiFormService,
    private userTracking: UfmUserTrackingService,
    private modalService: BsModalService,
    private udskrivService: UdskrivService) {
  }

  ngOnInit() {
    this.blankIndtastningsformular();
  }

  blankIndtastningsformular(): void {
    this.tilstand = Tilstand.Indtaster;
    const udland = new UdlandUddsted({});
    udland.adresse = new Adresse({ landekode: 'SE' });
    this.adresse = this.apiFormService.initialiserForm(udland);
  }

  private foldud() {
    if (this.foldudAmination !== foldsammen) {
      return;
    }
    this.vis = true;
    this.userTracking.sendUrl('/udland/vis');
    this.foldudAmination = foldud;
  }

  private foldsammen() {
    if (this.foldudAmination !== foldud) {
      return;
    }
    this.foldudAmination = foldsammen;
    this.userTracking.sendUrl('/udland/skjul');
    timer(400).subscribe(() => {
      if (this.foldudAmination === foldsammen) {
        this.vis = false;
      }
    });
  }

  aabenLukSide(topElement: HTMLElement): void {
    if (this.foldudAmination === foldsammen) {
      this.foldud();
      timer(400).subscribe(() => {
        topElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      });
    } else {
      this.foldsammen();
    }
  }

  lukSide(): void {
    this.foldsammen();
    this.blankIndtastningsformular();
  }

  annullerUdland(): void {
    this.userTracking.sendUrl('/udland/annuller');
    this.lukSide();
  }

  gemUdland(adresse: AdresseForm): void {
    this.tilstand = Tilstand.Sender;
    const udland = this.apiFormService.getUdland(adresse);
    this.rejsestraekningService.opretUdland(udland).subscribe({
      next: () => {
        this.tilstand = Tilstand.Kvittering;
        this.userTracking.sendUrl('/udland/ok');
        this.backendtidService.hentBackendtid();
        this.troOgLoveRejsetid = adresse.udlandUddSted.troOgLoveRejsetid;
        if (this.troOgLoveRejsetid === 'N') {
          this.visKvitteringAfslag();
        } else {
          this.visKvittering();
        }
        this.rejsetid = null;
        this.troOgLoveRejsetid = null;
        this.vis = false;
        //        this.gdpr = false;
       },
      error: () => {
        this.userTracking.sendUrl('/udland/fejl');
        this.tilstand = Tilstand.Indtaster;
      },
      complete: () => {
        if (this.tilstand !== Tilstand.Kvittering) {
          this.tilstand = Tilstand.Indtaster;
        }
      }
  });
  }

  visKvittering() {
    this.dialogRef = this.modalService.show(this.templateRef, {keyboard: false, ignoreBackdropClick: true});
  }

  visKvitteringAfslag() {
    this.dialogRefAfslag = this.modalService.show(this.templateRefAfslag);
  }

  lukKvittering(): void {
    this.dialogRef.hide();
    this.userTracking.sendUrl('/udland/kvittering/luk');
    this.lukSide();
  }

  lukKvitteringAfslag(): void {
    this.dialogRefAfslag.hide();
    this.userTracking.sendUrl('/udland/kvittering/luk');
  }

  udskriv() {
    this.udskrivService.udskriv();
  }

}

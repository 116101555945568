import { Component, EventEmitter, Input, Output, TemplateRef, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Observable, timer } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';
import { RejsestraekningService } from '../rejsestraekning.service';
import { Rejsestraekning } from '../rejsestraekning';
import { UdskrivService } from '../../../fælles-utils/udskriv-kvittering/udskriv.service';
import { UfmBackendtid2Service } from '@ufmit/ufm-backendtid2';

enum DialogType {
  KanIkkeSlettes,
  SekundaerEllerIkkeGodkendtPrimaer,
  GodkendtPrimaer,
  GodkendtPrimaerMedSekundaer
}

@Component({
  selector: 'app-rejsestraekning',
  templateUrl: './rejsestraekning.component.html',
  styleUrls: ['./rejsestraekning.component.scss']
})
export class RejsestraekningComponent implements OnInit {

  @Input() rejsestraekning: Rejsestraekning;
  @Output() sletRejsestraekning$ = new EventEmitter();

  dialogRef: BsModalRef;
  dialogTypeEnum = DialogType;
  dialogType: DialogType;
  sekundaereRejsestraekningerDerSlettes: Rejsestraekning[] = [];
  trin2Url: string;
  visBerig: boolean = false;
  foludBerig: boolean = false;

  constructor(
    private rejsestraekningService: RejsestraekningService,
    private backendtidService: UfmBackendtid2Service,
    private modalService: BsModalService,
    private udskrivService: UdskrivService,
    private userTracking: UfmUserTrackingService
  ) {}

  ngOnInit(): void {
    this.trin2Url = environment.trin2Url;
  }

  aabenBeritAnsoegning(): void {
    this.visBerig = true;
    timer(0).subscribe(() => {
      this.foludBerig = true;
    });
  }

  visSletDialog(template: TemplateRef<any>) {
    this.findDialogType()
      .pipe(first())
      .subscribe((dialogtype) => {
        if (dialogtype === DialogType.KanIkkeSlettes) {
          // rejsestrækning er blevet slettet i en anden session. hent rejsestrækninger fra API
          this.sletRejsestraekning$.emit();
          return;
        }
        this.dialogType = dialogtype;
        this.dialogRef = this.modalService.show(template, {});
      });
  }

  private findDialogType(): Observable<DialogType> {
    return this.rejsestraekningService.rejsestraekninger$
      .pipe(
        map((rejsestraekninger) => {
          let rejsestraekningType: DialogType;
          this.sekundaereRejsestraekningerDerSlettes.length = 0;
          rejsestraekninger.forEach((rejsestraekning) => {
            if (rejsestraekning.id === this.rejsestraekning.id) {
              if (!rejsestraekning.kanSlettes) {
                return DialogType.KanIkkeSlettes;
              }
              if (rejsestraekning.type === 'PRIMAER' && rejsestraekning.status === 'GODKENDT') {
                rejsestraekningType = DialogType.GodkendtPrimaer;
              } else {
                rejsestraekningType = DialogType.SekundaerEllerIkkeGodkendtPrimaer;
              }
            } else if (rejsestraekning.kanSlettes && rejsestraekning.type !== 'PRIMAER') {
              this.sekundaereRejsestraekningerDerSlettes.push(rejsestraekning);
            }
          });
          if (rejsestraekningType === DialogType.GodkendtPrimaer && this.sekundaereRejsestraekningerDerSlettes.length) {
            return DialogType.GodkendtPrimaerMedSekundaer;
          } else {
            return rejsestraekningType || DialogType.KanIkkeSlettes;
          }
        })
      );
  }

  dialogSvar(jaSlet: boolean): void {
    this.dialogRef.hide();
    if (jaSlet) {
      this.sletRejsestraekning();
    }
  }

  udskriv() {
    this.udskrivService.udskriv();
  }

  private sletRejsestraekning(): void {
    this.sletRejsestraekning$.emit(this.rejsestraekning);
    this.backendtidService.hentBackendtid();
  }

  sendTrin2UrlTilGA(): boolean {
    this.userTracking.sendUrl('/rejsestraekning/trin2/usikkert');
    return true;
  }

}

import { Component, OnInit } from '@angular/core';
import { timer, Observable } from 'rxjs';
import { map, distinctUntilChanged, filter, mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ProfilService } from '../../forretnings-sider/profil/profil.service';
import { RejsestraekningService } from '../../forretnings-sider/rejsestraekninger/rejsestraekning.service';
import { UfmBackendtid2Service } from '@ufmit/ufm-backendtid2';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';
import { UfmSessionLoginService, UfmSessionLogPaaStatus } from '@ufmit/ufm-session';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  isCollapsed = false;
  brugernavn: string;
  trin2Url: string;
  visPraktikpladsSide$: Observable<boolean>;
  erLoggetPaa: boolean;

  constructor(
    private loginService: UfmSessionLoginService,
    private profilService: ProfilService,
    private rejsestraekningService: RejsestraekningService,
    private ufmBackendtidService: UfmBackendtid2Service,
    private ufmFuldmagtService: UfmFuldmagtService,
    private ufmSessionLoginService: UfmSessionLoginService) {
    this.visPraktikpladsSide$ = rejsestraekningService.visPraktikpladsSide$;
  }

  logPaa(): boolean {
    this.loginService.logPaa();
    return false;
  }

  logAf(): boolean {
    this.loginService.logAf();
    return false;
  }

  ngOnInit() {
    this.visPraktikpladsSide$ = this.rejsestraekningService.visPraktikpladsSide$;
    // bugfix: chrome 69 + nvda skærmlæser. Viser uncollapsed version af menu i 50 millisekunder så nvda skærmlæser virker i chrome
    timer(50).subscribe(() => (this.isCollapsed = true));
    this.trin2Url = environment.trin2Url;
    this.ufmSessionLoginService.loggetPaa$.subscribe((erLoggetPaa) => (this.erLoggetPaa = erLoggetPaa === UfmSessionLogPaaStatus.LoggetPaa));
    this.ufmFuldmagtService.fuldmagtsBruger$.pipe(
      map((ufmFuldmagtBruger) => {
        if (ufmFuldmagtBruger && ufmFuldmagtBruger.agerendeCpr) {
          return ufmFuldmagtBruger.agerendeCpr;
        } else {
          return null;
        }
      }),
      distinctUntilChanged(),
      filter((agerendeCpr) => (agerendeCpr ? true : false)),
      mergeMap(() => this.profilService.getProfil())
    )
    .subscribe({
      next: (profil) => {
        this.brugernavn = profil ? profil.navn : null;
        this.rejsestraekningService.hentPotentielRejsestraekning();
        this.rejsestraekningService.hentRejsestraekninger();
        this.ufmBackendtidService.hentBackendtid();
      },
      error: (error) => {
        console.error('profil get error: error=', error);
      },    // errorHandler 
      complete: () => {}
    });
  }
}

import { Component, EventEmitter, Output } from '@angular/core';

import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

@Component({
  selector: 'app-gdpr-checkbox',
  templateUrl: './gdpr-checkbox.component.html',
  styleUrls: ['./gdpr-checkbox.component.scss']
})
export class GdprCheckboxComponent {

  @Output() gdprLaest$ = new EventEmitter();

  constructor(private userTracking: UfmUserTrackingService) {}

  gdprRettet(laest): void {
    if (laest) {
      this.userTracking.sendUrl('/gdpr-checkbox/ja');
    } else {
      this.userTracking.sendUrl('/gdpr-checkbox/nej');
    }
    this.gdprLaest$.emit(laest);
  }
}

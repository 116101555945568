import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment/moment';

import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

@Injectable({
  providedIn: 'root'
})
export class GdprService {

  constructor(private cookieService: CookieService, private userTracking: UfmUserTrackingService) { }

  erGdprGodkendt(): boolean {
    return this.cookieService.get('gdpr') === 'ja';
  }

  godkendGdpr(): void {
    const udloebsdato = this.userTracking.accepteret() ? moment().add(10, 'years').toDate() : 0 ;
    this.cookieService.set('gdpr', 'ja', udloebsdato);
  }
}

<form [formGroup]="form">
    <div class="row">
  
      <div *ngIf="this.label" class="col-12 pb-2" [innerHTML]="labelSafe"></div>
      <div *ngFor="let fil of form.controls; let i = index;" class="pt-1 col-12 form-group bg-white mb-0 py-0">
        <app-fil [form]="fil" [index]="i" [filUploaded$]="filUploaded$" [ariaLabel]="ariaLabelFil"></app-fil>
      </div>
      <div class="col-12 pt-2">
        <a href="#" (click)="uploadFlereDokumenter()" (keyup.enter)="uploadFlereDokumenter()"
          [attr.aria-label]="ariaLabelTilfoejFiler">
          <fa-icon [icon]="faPlusCircle"></fa-icon>
          Flere filer
        </a>
      </div>
      
    </div>
  </form>

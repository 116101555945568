<ufm-user-tracking class="no-print"></ufm-user-tracking>
<ngx-loading-bar color="#e6821e" [includeSpinner]="false"></ngx-loading-bar>
<app-menu class="no-print"></app-menu>
<ufm-side-backendtid></ufm-side-backendtid>
<ufm-fuldmagt [priv]="priv"></ufm-fuldmagt>
<div class="container mt-4 flex-fill" style="background-image: url('api/miljo.svg')">  
  <main role="main" [ngClass]="noprint$ | async">
    <router-outlet></router-outlet>
  </main>
  <app-side-bund class="no-print"></app-side-bund>
</div>
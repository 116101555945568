import { trigger, transition, animate, style, state } from '@angular/animations';

export const foldudAmination = trigger('foldudAmination', [
    state('foldud', style({
        height: '*',
        opacity: 1
    })),
    state('foldsammen', style({
        height: '0px',
        opacity: 0
    })),
    transition('foldud <=> foldsammen', [
        animate('0.4s')
    ]),
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('700ms ease-in', style({ opacity: 1 }))
  ]),
  transition(':leave', [
    animate('350ms ease-in', style({ opacity: 0 }))
  ])
]);

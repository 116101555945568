import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { AdressePipe } from '../adresse-pipe/adresse.pipe';

import { DawaAutoCompleteResultat } from './dawa-auto-complete-resultat';
import { DawaAdresseMiniResultat } from './dawa-adresse-mini-resultat';
import { DawaAdresseVaskResultat } from './dawa-adresse-vask-resultat';
import { DawaAdgangsAdresseVaskResultat } from './dawa-adgangs-adresse-vask-resultat';
import { DawaPostnummerResultat } from './dawa-postnummer-resultat';

import { Adresse } from '../adresse';
import { AdresseForm } from '../adresse-form/adresse-form';

@Injectable({
  providedIn: 'root'
})
export class DawaAdresseService {

  dawaPostnumre: DawaPostnummerResultat[];

  constructor(private http: HttpClient, private adressePipe: AdressePipe) { }

  dawaHeaders = { NoInterceptorError: 'x', NoInterceptorTimeout: 'x', ignoreLoadingBar: '' };

  autocompleteAdresse(adresse: string): Observable<DawaAutoCompleteResultat[]> {
    return this.http.get<DawaAutoCompleteResultat[]>('/dawa/autocomplete',
      { params: { per_side: '7', q: adresse, caretpos: adresse.length.toString() }, headers: this.dawaHeaders });
  }

  private adresseMedKommune(adresse: string | Adresse | AdresseForm): Observable<DawaAdresseMiniResultat[]> {
    const betegnelse: string = this.betegnelse(adresse);
    return this.http
      .get<DawaAdresseMiniResultat[]>('/dawa/adresser',
        { params: { struktur: 'mini', q: betegnelse }, headers: this.dawaHeaders })
      .pipe(
        tap(
          (res) => {
            if (!res) {
            }
          }
        )
      );
  }

  hentKommunekode(adresse: string | Adresse | AdresseForm): Observable<string> {
    return this.adresseMedKommune(adresse).pipe(
      map((adresserMedKommune) => {
          const kommunekoder: string[] = adresserMedKommune.map((adresseMedKommune) => adresseMedKommune.kommunekode.substr(1));
          const unikkeKommunekoder: string[] = Array.from(new Set(kommunekoder));
          if (unikkeKommunekoder.length === 1) {
            return unikkeKommunekoder[0];
          } else {
            return null;
          }
        }
      )
    );
  }

  adresseVask(adresse: string | Adresse | AdresseForm): Observable<DawaAdresseVaskResultat> {
    const betegnelse: string = this.betegnelse(adresse);
    return this.http
      .get<DawaAdresseVaskResultat>('/dawa/datavask/adresser',
         { params: { betegnelse: betegnelse }, headers: this.dawaHeaders })
      .pipe(
        map(
          (adressevask) => {
            this.fjernStatus24Resultater(adressevask);
            return adressevask;
          }
        )
      );
  }

  adgangsadresseVask(adresse: string | Adresse | AdresseForm): Observable<DawaAdgangsAdresseVaskResultat> {
    const betegnelse: string = this.betegnelse(adresse);
    return this.http
      .get<DawaAdgangsAdresseVaskResultat>('/dawa/datavask/adgangsadresser',
         { params: { betegnelse: betegnelse }, headers: this.dawaHeaders })
      .pipe(
        map(
          (adressevask) => {
            this.fjernStatus24Resultater(adressevask);
            return adressevask;
          }
        )
      );
  }

  adresseVaskEllerAdgangsadresseVask(adresse: string | Adresse | AdresseForm, brugAdgangsadresseVask: boolean): Observable<DawaAdresseVaskResultat | DawaAdgangsAdresseVaskResultat> {
    if (brugAdgangsadresseVask) {
      return this.adgangsadresseVask(adresse);
    } else {
      return this.adresseVask(adresse);
    }
  }

  betegnelse(adresse: string | Adresse | AdresseForm): string {
    if (typeof adresse === 'string') {
      return adresse;
    } else {
      return this.adressePipe.transform(adresse);
    }
  }

  private fjernStatus24Resultater(adresseVask: DawaAdresseVaskResultat | DawaAdgangsAdresseVaskResultat) {
    for (let i = adresseVask.resultater.length - 1; i >= 0; i--) {
      if ([2, 4].indexOf(adresseVask.resultater[i].aktueladresse.status) !== -1) {
        adresseVask.resultater.splice(i, 1);
      }
    }
    if (!adresseVask.resultater.length) {
      adresseVask.kategori = 'C';
    }
  }

  hentPostnumre(): Observable<DawaPostnummerResultat[]> {
    if (this.dawaPostnumre) {
      return of(this.dawaPostnumre);
    }
    return this.http
      .get<DawaPostnummerResultat[]>('/dawa/postnumre', { headers: this.dawaHeaders })
      .pipe(
        tap((dawaPostnumre) => (this.dawaPostnumre = dawaPostnumre)),
        catchError((error) => {
          this.dawaPostnumre = [] ;
          return of(this.dawaPostnumre);
        })
      );
  }
}

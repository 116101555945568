import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { BerigAnsoegningService } from './berig-ansoegning.service';
import { foldudAmination } from '../../fælles-utils/animationer/animationer';
import { Rejsestraekning } from '../rejsestraekninger/rejsestraekning';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';
import { Tilstand } from '../../fælles-utils/tilstand-ui/tilstand.enum';

const foldud = 'foldud';
const foldsammen = 'foldsammen';

@Component({
  selector: 'app-berig-ansoegning',
  templateUrl: './berig-ansoegning.component.html',
  styleUrls: ['./berig-ansoegning.component.scss'],
  animations: [foldudAmination]
})
export class BerigAnsoegningComponent implements OnInit {

  @Input() rejsestraekning: Rejsestraekning;
  @Input() vis: boolean;
  @Output() afslutIndtastning$ = new EventEmitter();

  tilstand: Tilstand;
  tilstandEnum = Tilstand;
  foldudAmination = foldsammen;
  @ViewChild('berigStart', { static: true }) berigStartHTML: ElementRef;
  form: UntypedFormGroup;
  idPrefix: string;
  dialogRef: BsModalRef;
  @ViewChild('kvittering', { static: true }) templateRef: TemplateRef<any>;
  filer: any[] = [];

  constructor(
    private berigAnsoegningService: BerigAnsoegningService,
    private modalService: BsModalService,
    private userTracking: UfmUserTrackingService) {}

  ngOnInit(): void {
      this.form = this.berigAnsoegningService.initialiserBerigAnsoegningForm(this.rejsestraekning.ansoegningSekvensNr);
      this.idPrefix = 'berig_' + this.rejsestraekning.ansoegningSekvensNr;
      this.tilstand = Tilstand.Indtaster;
      timer(0).subscribe(() => this.foldud());
  }

  private foldud() {
    if (this.foldudAmination !== foldsammen) {
      return;
    }
    this.foldudAmination = foldud;
    this.userTracking.sendUrl('/berig/vis');
  }

  private foldsammen() {
    if (this.foldudAmination !== foldud) {
      return;
    }
    this.foldudAmination = foldsammen;
    this.userTracking.sendUrl('/berig/skjul');
  }

  aabenLukSide(topElement: HTMLElement): void {
    if (this.foldudAmination === foldsammen) {
      this.foldud();
      timer(400).subscribe(() => {
        topElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      });
    } else {
      this.foldsammen();
    }
  }

  setGdpr(gdpr: boolean) {
    this.form.controls.gdpr.setValue(gdpr);
  }

  filUploaded(filUploaded: boolean) {
    this.form.controls.filer.updateValueAndValidity();
  }

  annullerIndtastning(): void {
    this.foldsammen();
    timer(800).subscribe(() => this.afslutIndtastning$.emit());
  }

  sendDokumenter(): void {
    this.tilstand = Tilstand.Sender;
    this.berigAnsoegningService.sendBerigetAnsoegning(this.form).subscribe({
      next: (ok) => {
        this.filer = [];
        const filer: UntypedFormArray = this.form.controls.filer as UntypedFormArray;
        for (let i = 0 ; i < filer.length ; i++) {
          const fil: UntypedFormGroup = filer.at(i) as UntypedFormGroup;
          this.filer.push({ filnavn: fil.controls.filnavn.value });
        }
        this.visKvittering();
        this.afslutIndtastning$.emit();
      },
      error: (error) => {
        console.error(error);
        this.tilstand = Tilstand.Indtaster;
      },
      complete: () => {
      }
    })
  }

  visKvittering() {
    this.tilstand = Tilstand.Kvittering;
    this.userTracking.sendUrl('/berig/kvittering');
    this.dialogRef = this.modalService.show(this.templateRef, {keyboard: false, ignoreBackdropClick: true});
  }

  lukKvittering(): void {
    this.dialogRef.hide();
    this.userTracking.sendUrl('/berig/kvittering/luk');
    this.lukSide();
  }

  lukSide(): void {
    this.foldsammen();
  }

}

import { Adresse } from '../../fælles-utils/adresse/adresse';

export class EkstraUddsted {
  public adresse: Adresse;
  public stedNavn: string;
  public fra: Date;
  public til: Date;
  public rejsetid: number;
  public troOgLoveRejsetid: string;

  constructor(json: any) {
    if (json.adresse) {
      this.adresse = new Adresse(json.adresse);
    }
    this.stedNavn = json.stedNavn;
    this.fra = json.fra;
    this.til = json.til;
    this.rejsetid = parseInt(json.rejsetid, 10);
    this.troOgLoveRejsetid = json.troOgLoveRejsetid;
}
}

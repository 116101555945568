import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';
import { map, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FuldmagtGuard  {

    constructor(private ufmFuldmagtService: UfmFuldmagtService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.ufmFuldmagtService
          .getCachedFuldmagtsBruger(environment.fuldmagtsprivilege)
          .pipe(
            filter((ufmFuldmagtBruger) => (ufmFuldmagtBruger ? true : false)),
            map((fuldmagtsBruger) => {
                if (fuldmagtsBruger && fuldmagtsBruger.agerendeCpr) {
                    return true;
                } else {
                    return false;
                }
            })
        );
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { RejsestraekningService } from './rejsestraekning.service';
import { Rejsestraekning } from './rejsestraekning';
import { SlettetRejsestraekning } from './slettet-rejsestraekning';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';

@Component({
  selector: 'app-rejsestraekninger',
  templateUrl: './rejsestraekninger.component.html',
  styleUrls: ['./rejsestraekninger.component.scss']
})
export class RejsestraekningerComponent implements OnInit {

  @Input() visKunPrimaer = false;
  @Input() skjulPrimaer = false;
  @Input() skjulAnnullerede = false;
  @Output() rejsestraekningerErTom$ = new EventEmitter();

  rejsestraekninger: Rejsestraekning[] = [];

  constructor(private rejsestraekningService: RejsestraekningService, private ufmFuldmagtService: UfmFuldmagtService) { }

  ngOnInit() {
    this.rejsestraekningService.rejsestraekninger$
    .subscribe(
      (rejsestraekninger) => {
        this.rejsestraekninger = rejsestraekninger;
        this.rejsestraekningerErTom$.next(this.rejsestraekningerMedInputFilter().length === 0);
      });
    this.ufmFuldmagtService.skiftetFuldmagtsBruger$.subscribe((skiftet) => {
      if (skiftet) {
        this.rejsestraekningService.hentRejsestraekninger();
      }
    });
  }

  rejsestraekningerMedInputFilter(): Rejsestraekning[] {
    return this.rejsestraekninger
    .filter((rejsestraekning) => {
      if (!this.visKunPrimaer) {
        return true;
      }
      return rejsestraekning.type === 'PRIMAER';
    })
    .filter((rejsestraekning) => {
      if (!this.skjulPrimaer) {
        return true;
      }
      return rejsestraekning.type !== 'PRIMAER';
    })
    .filter((rejsestraekning) => {
      if (!this.skjulAnnullerede) {
        return true;
      }
      return rejsestraekning.status !== 'ANNULERET';
    });
  }

  sletRejsestraekning(rejsestraekning: Rejsestraekning): void {
    if (!rejsestraekning) {
      // slet dialog afbrudt. rejsestrækning er allerede blevet slettet i en anden session
      this.rejsestraekningService.hentRejsestraekninger();
      return;
    }
    const sletRejsestraekning = new SlettetRejsestraekning(rejsestraekning.id);
    this.rejsestraekningService
      .sletRejsestraekning(sletRejsestraekning)
      .subscribe({
        next: () => {
          this.rejsestraekningService.hentRejsestraekninger();
        }
      });
  }
}

<div class="row">
  <address class="col-12 col-lg-4 mb-0">
    <div class="row">
      <div *ngIf="conavn" class="col-12">c/o {{ conavn }}</div>
      <div class="col-12">{{ adresse | adresse:1 }}</div>
      <div class="col-12">{{ adresse | adresse:2 }}</div>
      <div class="col-12">{{ adresse | adresse:3 }}</div>
    </div>
  </address>
  <div class="col-12 col-lg-4 mb-0">
    <span *ngIf="flyttedato" class="ufm-orange text-dark p-1">Gældende fra {{ flyttedato | langdato }}</span>
  </div>
  <div class="col-12 col-lg-4 mb-0"></div>
</div>
<br />

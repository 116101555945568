import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'landenavn'
})
export class LandenavnPipe implements PipeTransform {

  transform(landekode: string): string {
    switch (landekode) {
      case 'SE' : return 'Sverige';
      case 'DE' : return 'Tyskland';
      default: return '';
    }
  }
}

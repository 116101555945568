<div #berigStart></div>

<div [@foldudAmination]="foldudAmination">
  <main *ngIf="vis && tilstand !== tilstandEnum.Kvittering">
    <form [formGroup]="form">
      <app-filer class="col-12" [form]="form.controls.filer" [accept]="'.pdf'"
        [idPrefix]="idPrefix"
        [label]="'<br />Vedlæg ekstra dokumentation til denne ansøgning'"
        (filUploaded$)="filUploaded($event)" (keydown.enter)="$event.preventDefault()">
      </app-filer>
      <app-gdpr-checkbox class="col-12" (gdprLaest$)="setGdpr($event)"></app-gdpr-checkbox>
      <br />
      <div class="col-12">
        <div class="row">
          <div class="col-6 col-lg-4 col-xl-4">
            <button (click)="annullerIndtastning()" type="submit" class="btn btn-outline-secondary d-block w-100" aria-label="Annuller indtastning">
              Annuller
            </button>
          </div>
          <div class="col-6 col-lg-4 col-xl-4">
            <button (click)="sendDokumenter()" [disabled]="(!form.valid || sender)" type="submit" class="btn btn-primary d-block w-100">
              Send dokumenter
            </button>
          </div>    
        </div>
      </div>
    </form>
  </main>
</div>

<ng-template #kvittering>
  <div class="modal-header">
    <h3 class="modal-title">Kvittering - tilføj dokumenter</h3>
    <button type="button" class="close pull-right" aria-label="Luk kvittering" (click)="dialogRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Du kan gemme eller udskrive denne kvittering for uploaded dokumentation til senere brug.
    </p>
    <div class="row">
      <div class="col-12">
        Navn på uddannelsessted/praktiksted: {{ rejsestraekning.lokation.adresse.navn }}
      </div>
      <div class="col-12">
        Adresse: {{ rejsestraekning.lokation.adresse | adresse }}
      </div>
      <div class="col-6">
        Fra dato: {{ rejsestraekning.fra | langdato }}
      </div>
      <div class="col-6">
        Til dato: {{ rejsestraekning.til | langdato }}
      </div>
      <app-kvittering-filer [filer]="filer" class="row pb-3"></app-kvittering-filer>
      <app-gdpr-laes-mere class="col-12"></app-gdpr-laes-mere>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" attr.aria-label="Udskriv kvittering" (click)="udskriv()">
      Udskriv
    </button>
    <button type="button" class="btn btn-primary" attr.aria-label="Luk kvittering" (click)="lukKvittering()">
      Luk
    </button>
  </div>
</ng-template>


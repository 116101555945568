export class GodkendtRejsestraekning {

  constructor(public readonly id: number,
              public readonly fra: Date,
              public readonly til: Date,
              public readonly institutionsId: number,
              public readonly lokationsId: number,
              public readonly rejsetid: number,
              public readonly troOgLoveRejsetid: string) {
  }
}

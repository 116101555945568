import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { RejsestraekningService } from '../rejsestraekninger/rejsestraekning.service';

@Component({
  selector: 'app-forside',
  templateUrl: './forside.component.html',
  styleUrls: ['./forside.component.scss']
})
export class ForsideComponent implements OnInit {

  visPraktikpladsSide$: Observable<boolean>;
  andreRejsestraekningerErTom: boolean;

  constructor(private rejsestraekningService: RejsestraekningService) { }

  ngOnInit() {
    this.visPraktikpladsSide$ = this.rejsestraekningService.visPraktikpladsSide$;
  }
}

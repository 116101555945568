import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { FilerService } from '../../fælles-utils/filer/filer.service';

function validerMindstEnFil(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const filer: UntypedFormArray = control as UntypedFormArray;
    for (let i = 0; i < filer.length; i++) {
      const fil: UntypedFormGroup = filer.at(i) as UntypedFormGroup;
      if (fil.controls.data.value) {
        return null;
      }
    }
    return { filMangler: true };
  };
}

@Injectable({
  providedIn: 'root'
})
export class BerigAnsoegningService {

  constructor(
    private fb: UntypedFormBuilder,
    private filService: FilerService,
    private http: HttpClient,
    private toastrService: ToastrService) { }

  initialiserBerigAnsoegningForm(ansoegningSekvensNr: number): UntypedFormGroup {
    return this.fb.group({
      ansoegningSekvensNr: [ansoegningSekvensNr],
      filer: this.fb.array([this.filService.nyFil('berig_' + ansoegningSekvensNr)], { validators: [validerMindstEnFil()] }),
      gdpr: [null, Validators.requiredTrue]
    });
  }

  sendBerigetAnsoegning(form: UntypedFormGroup): Observable<any> {
    const formData = new FormData();
    let totalFilsize = 0;
    // tilføj application/json
    const ansoegningSekvensNr: number = form.controls.ansoegningSekvensNr.value;
    const ansoegningJson: any = { ansoegningSekvensNr: ansoegningSekvensNr };
    const ansoegningBlob = new Blob([JSON.stringify(ansoegningJson)], { type: 'application/json' });
    formData.append('berigetAnsoegning', ansoegningBlob);
    totalFilsize = totalFilsize += ansoegningBlob.size;
    // tilføj pdf-filer
    const filer: UntypedFormArray = form.controls.filer as UntypedFormArray;
    for (let i = 0 ; i < filer.length ; i++) {
      const fil: UntypedFormGroup = filer.at(i) as UntypedFormGroup;
      const filnavn: string = fil.controls.filnavn.value;
      const data: Blob = fil.controls.data.value;
      if (filnavn && data) {
        formData.append('dokument', data, filnavn);
        totalFilsize = totalFilsize += data.size;
      }
    }
    if (totalFilsize > 20971580) {
      const max20msg: string = 'Du kan maksimalt uploade 20MB i alt.';
      this.toastrService.error(max20msg);
      return throwError(() => new Error('test'));      
    }
    return this.http.post(environment.ukApi + '/rejsestraekninger/berig/', formData);
  }

}

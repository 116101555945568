import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilerService {

  constructor(private http: HttpClient, private formBuilder: UntypedFormBuilder) { }

  ValiderPDF(file: File) {
    const formData = new FormData();
    formData.append('dokument', file);
    const headers = { 'Accept-Language': 'da' };
    return this.http.post(environment.ukApi + '/validatepdf', formData, { headers });
  }

  nyFil(gruppe: string): UntypedFormGroup {
    return this.formBuilder.group({
      gruppe: [gruppe, Validators.required],
      fil: [null],
      filnavn: [null],
      data: [null],
      dataAsBase64: [null]
    });
  }

}

<div>
    <div class="row pt-4">
        <div class="col-12">
            <h1 class="display-1">Ungdomskort trin 1</h1>
            <h3><small class="text-muted">Bliv godkendt til at købe et Ungdomskort hos trafikselskaberne</small></h3>
            Her kan du blive godkendt til Ungdomskort og se de rejsestrækninger, du lige nu er godkendt til.
            Skal du skifte uddannelsessted, flytte, i praktik eller lignende, skal du registrere det her.
            Du bestiller og betaler dit Ungdomskort i trafikselskabernes bestillingssystem Mit Ungdomskort.
        </div>
        <app-gdpr-laes-mere class="col-12"></app-gdpr-laes-mere>
    </div>

    <div class="row pt-4">
        <div class="col-12">
            <h2>Din rejsestrækning</h2>
        </div>
    </div>

    <div class="row pt-4">
        <div class="col-12">
            <h4>Fra din bopæl</h4>
            <hr />
            <app-profil></app-profil>
        </div>
    </div>

    <div class="row pt-4">
        <div class="col-12">
            <h4>Til dit uddannelsessted</h4>
            <hr />
            <app-godkendelse [redirectEfterGodkend]="null" class="col-12 pt-2">
            </app-godkendelse>
            <app-rejsestraekninger [visKunPrimaer]="true" [skjulPrimaer]="false" [skjulAnnullerede]="true" class="col-12 pt-3">
            </app-rejsestraekninger>
        </div>
    </div>

    <div class="row pt-4">
        <div class="col-12">
            <h4 *ngIf="!andreRejsestraekningerErTom">Til andre adresser</h4>
            <hr />
            <app-rejsestraekninger [visKunPrimaer]="false" [skjulPrimaer]="true" [skjulAnnullerede]="true" class="col-12 pt-3">
            </app-rejsestraekninger>
            <app-ekstra-uddsted *ngIf="visPraktikpladsSide$ | async" class="col-12 pt-3"></app-ekstra-uddsted>
            <app-praktik *ngIf="visPraktikpladsSide$ | async" class="col-12 pt-3"></app-praktik>
            <app-udland-uddsted class="col-12 pt-3"></app-udland-uddsted>
        </div>
    </div>
</div>

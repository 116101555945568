import { Pipe, PipeTransform } from '@angular/core';

import { AdresseForm } from '../adresse-form/adresse-form';
import { Adresse } from '../adresse';
import { PostnrPipe } from '../postnr-pipe/postnr.pipe';
import { LandenavnPipe } from '../landenavn-pipe/landenavn.pipe';

@Pipe({
  name: 'adresse'
})
export class AdressePipe implements PipeTransform {

  constructor(private postnr: PostnrPipe, private landenavn: LandenavnPipe) {}

  transform(adresse: Adresse | AdresseForm, adresseLinje?: number): string {
    if (adresseLinje === 1) {
      return [adresse.vejnavn, adresse.husnr, adresse.etage, adresse.sidedoer].join(' ');
    } else if (adresseLinje === 2) {
      return [this.postnr.transform(adresse), adresse.bynavn].join(' ');
    } else if (adresseLinje === 3) {
      return this.landenavn.transform(adresse.landekode);
    } else {
      return [adresse.vejnavn, adresse.husnr, adresse.etage, adresse.sidedoer, ',',
      this.postnr.transform(adresse), adresse.bynavn, this.landenavn.transform(adresse.landekode)].join(' ');
    }
  }
}

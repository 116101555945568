import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';
import { ProfilService } from '../profil/profil.service';
import { RejsestraekningService } from '../rejsestraekninger/rejsestraekning.service';
import { UdskrivService } from '../../../app/fælles-utils/udskriv-kvittering/udskriv.service';

import { Adresse } from '../../fælles-utils/adresse/adresse';
import { GodkendtRejsestraekning } from '../rejsestraekninger/godkendt-rejsestraekning';
import { PotentielRejsestraekning } from '../rejsestraekninger/potentiel-rejsestraekning';
import { UddannelsesLokation } from '../../fælles-utils/adresse/uddannelses-lokation';
import { Tilstand } from '../../fælles-utils/tilstand-ui/tilstand.enum';
import { UfmBackendtid2Service } from '@ufmit/ufm-backendtid2';
import { UfmFuldmagtService } from '@ufmit/ufm-fuldmagt';

@Component({
  selector: 'app-godkendelse',
  templateUrl: './godkendelse.component.html',
  styleUrls: ['./godkendelse.component.scss']
})
export class GodkendelseComponent implements OnInit {

  tilstand: Tilstand;
  tilstandEnum = Tilstand;

  @Input() redirectEfterGodkend = '/rejsestraekninger';

  potentielRejsestraekning: PotentielRejsestraekning = null;
  institutionsnavn: string;
  lokationer: UddannelsesLokation[] = [];
  valgtLokationIndex: number;
  valgtLokation: UddannelsesLokation = null;
  rejsetid: string;
  troOgLoveRejsetid: string;
  gdpr: boolean;
  trin2Url: string;
  dialogRef: BsModalRef;
  @ViewChild('kvittering', { static: true }) templateRef: TemplateRef<any>;
  dialogRefAfslag: BsModalRef;
  @ViewChild('afslag', { static: true }) templateRefAfslag: TemplateRef<any>;
  indskrivningMangler$: Observable<boolean>;
  bopaelAdresse: Adresse;
  conavn: string;
  rejsetidFraBopaelTilLokation: string;
  udenlandskAdresse: boolean;

  constructor(
    private profilService: ProfilService,
    private rejsestraekningService: RejsestraekningService,
    private backendtidService: UfmBackendtid2Service,
    private router: Router,
    private userTracking: UfmUserTrackingService,
    private modalService: BsModalService,
    private cookieService: CookieService,
    private udskrivService: UdskrivService,
    private ufmFuldmagtService: UfmFuldmagtService
  ) {
    this.indskrivningMangler$ = this.rejsestraekningService.indskrivningMangler$;
  }

  ngOnInit() {
    this.trin2Url = environment.trin2Url;
    this.tilstand = Tilstand.Indlaeser;
    this.ufmFuldmagtService.skiftetFuldmagtsBruger$.subscribe((skiftet) => {
      if (skiftet) {
        this.hentPotentielRejsestraekning();
      }
    });
    // tjek om rejsetid kan beregnes. Pt. kun muligt for danske rejestrækninger.
    this.profilService.profil$.subscribe((profil) => {
      if (profil) {
        this.udenlandskAdresse = profil.udenlandskAdresse;
        if (this.udenlandskAdresse) {
          this.rejsetidFraBopaelTilLokation = '';
        }
      }
    });
  }

  hentPotentielRejsestraekning(): void {
    this.rejsestraekningService.potentielRejsestraekning$.subscribe((potentielRejsestraekning) => {
      this.tilstand = Tilstand.Viser;
      if (potentielRejsestraekning) {
        this.valgtLokation = null;
        this.potentielRejsestraekning = potentielRejsestraekning;
        this.institutionsnavn = potentielRejsestraekning.institution.navn;
        this.lokationer = potentielRejsestraekning.lokationer;

        if (this.lokationer.length === 1) {
          this.valgtLokation = this.lokationer[0];
          this.valgtLokationIndex = 1;
          this.updateRejsetid(0);
        }
      } else {
        this.potentielRejsestraekning = null;
        this.lokationer = [];
      }
    });
  }

  public godkend(): void {
    this.valgtLokation = this.lokationer[this.valgtLokationIndex - 1];
    const godkendtRejsestraekning = new GodkendtRejsestraekning(
      this.potentielRejsestraekning.id,
      this.potentielRejsestraekning.fra,
      this.potentielRejsestraekning.til,
      this.potentielRejsestraekning.institution.id,
      this.valgtLokation.id,
      parseInt(this.rejsetid, 10),
      this.troOgLoveRejsetid
    );
    this.tilstand = Tilstand.Sender;
    this.potentielRejsestraekning = null;
    this.rejsestraekningService
      .godkendtRejsestraekning(godkendtRejsestraekning)
      .subscribe(
        () => {
          this.tilstand = Tilstand.Kvittering;
          this.userTracking.sendUrl('/godkendelse/ok');
          if (this.troOgLoveRejsetid === 'N') {
            this.visKvitteringAfslag();
          } else {
            this.visKvittering();
          }
          this.rejsetid = null;
          this.troOgLoveRejsetid = null;
          this.gdpr = false;
        },
        () => {
          this.tilstand = Tilstand.Viser;
          this.userTracking.sendUrl('/godkendelse/fejl');
        },
        () => {
          if (this.tilstand !== Tilstand.Kvittering) {
            this.tilstand = Tilstand.Viser;
          }
        }
      );
  }

  visKvittering() {
    const profil = this.profilService.profil$.value;
    if (profil.flytning) {
      this.bopaelAdresse = profil.flytning.adresse;
      this.conavn = profil.flytning.conavn;
    } else {
      this.bopaelAdresse = profil.adresse;
      this.conavn = null;
    }
    this.backendtidService.hentBackendtid();
    this.dialogRef = this.modalService.show(this.templateRef);
  }

  visKvitteringAfslag() {
    this.dialogRefAfslag = this.modalService.show(this.templateRefAfslag);
  }

  udskriv() {
    this.udskrivService.udskriv();
  }

  lukKvittering(): void {
    this.dialogRef.hide();
    this.userTracking.sendUrl('/godkendelse/kvittering/luk');
  }

  lukKvitteringAfslag(): void {
    this.dialogRefAfslag.hide();
    this.userTracking.sendUrl('/godkendelse/kvittering/luk');
  }

  fortsaetTilTrin2SikkertMedLogAf(): void {
    this.cookieService.set('fortsaet_til_trin_2', 'ja tak');
    this.userTracking.sendUrl('/godkendelse/trin2/sikkert');
    this.router.navigate(['/logaf']);
  }

  fortsaetTilTrin2UsikkertUdenLogAf(): boolean {
    this.userTracking.sendUrl('/godkendelse/trin2/usikkert');
    return true;
  }

  updateRejsetid(n: number) {
    if (this.udenlandskAdresse) {
      return;
    }
    this.rejsestraekningService
      .getRejsetid(this.lokationer[n].adresse)
      .subscribe((result) => {
        if (result > 0) {
          const timer: number = Math.floor(result / 60);
          const minutter: number = result % 60;
          let timerText = '';
          let minutterText = '';
          let konjunktionText = '';
          if (timer > 0 && minutter > 0) {
            konjunktionText = ' og ';
          }
          if (timer === 1) {
            timerText = timer + ' time';
          } else if (timer > 1) {
            timerText = timer + ' timer';
          }
          if (minutter === 1) {
            minutterText = minutter + ' minut';
          } else if (minutter > 1) {
            minutterText = minutter + ' minutter';
          }
          this.rejsetidFraBopaelTilLokation = 
            'Vi har registreret, ved opslag i rejseplanen i myldretiden på din strækning, at rejsetiden er '
            + timerText + konjunktionText + minutterText + '.';

        } else {
          this.rejsetidFraBopaelTilLokation = '';
        }

        return result;
    });
  }
}

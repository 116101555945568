<div *ngIf="tilstand === tilstandEnum.Indlaeser">
  Indlæser..
</div>

<div *ngIf="tilstand !== tilstandEnum.Indlaeser && profil.navn" class="row">
  <div class="col-12">
    <div *ngIf="tilstand === tilstandEnum.Viser">
      <app-vis-adresse
        [flyttedato]="profil.flytning ? profil.flytning.flyttedato : null"
        [conavn]="profil.flytning ? profil.flytning.conavn : profil.conavn"
        [adresse]="profil.flytning ? profil.flytning.adresse : profil.adresse"
      >
      </app-vis-adresse>
      <button (click)="visForm()" type="submit" class="btn btn-sm btn-outline-primary">Redigér</button>
    </div>
  </div>

  <div *ngIf="tilstand === tilstandEnum.Indtaster || tilstand === tilstandEnum.Sender">
    <div class="col-12">
        <div class="bg-primary-purple text-white p-1">Indtast ny adresse</div>
    </div>
    <div class="col-12">
      Skal du flytte, skal du indtaste din nye adresse her. Du skal selv huske at melde flytning til
      folkeregistret via borger.dk, da dine data her ikke videregives til dem.
    </div>
    <div class="col-12">
      <app-adresse-form 
        [adresse]="adresse" 
        [kommuner]="kommuner" 
        submitKnapTekst="Meld flytning" 
        [sender]="tilstand === tilstandEnum.Sender"
        (annullerIndtastning$)="annullerFlytning()" 
        (gemIndtastning$)="gemFlytning($event)">
      </app-adresse-form>
    </div>
  </div>
</div>

<div *ngIf="tilstand !== tilstandEnum.Indlaeser && !profil.navn" class="row">
  <div class="col-12">
    <div class="alert alert-danger" role="alert">
      Vi har ikke kunnet slå dig op i CPR. Log af og prøv igen senere.
    </div>
  </div>
</div>

<ng-template #kvittering>
  <div class="modal-header">
    <div *ngIf="flytning.adresse.landekode === 'DK'">
      <h4 class="modal-title">Kvittering - adresseændring indenfor Danmark</h4>
    </div>
    <div *ngIf="flytning.adresse.landekode !== 'DK'">
      <h4 class="modal-title">Kvittering - adresseændring til udlandet</h4>
    </div>
    <button type="button" class="close pull-right" aria-label="Luk kvittering" (click)="dialogRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="flytning.adresse.landekode === 'DK'">
      <p>
        Du kan gemme eller udskrive denne kvittering til senere brug. Du skal huske at
        melde flytning til folkeregistret via borger.dk, da vi ikke sender dine data videre.
      </p>
    </div>
    <div *ngIf="flytning.adresse.landekode !== 'DK'">
      <p>
        Henvend dig til dit uddannelsessted med dokumentation for flytningen fra 
        Skattevärket eller Burgeramt, eller ved kopi af fx lejekontrakt, 
        huslejekvittering, sundhedskort eller lønseddel, hvor dit navn og adresse fremgår. 
        Når dit uddannelsessted har godkendt din nye adresse, kan du gå videre til Trin 2.
      </p>
    </div>
    <div class="row">
      <div class="col-12">
        {{ 'Du har oplyst, at du den ' + (flytning.flyttedato | langdato) + ' flytter til:' }}
      </div>
      <div *ngIf="flytning.conavn" class="col-12">
        c/o {{ flytning.conavn }}
      </div>
      <div class="col-12">
        Adresse: {{ flytning.adresse | adresse }}
      </div>
    </div>
    <app-gdpr-laes-mere class="col-12"></app-gdpr-laes-mere>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" attr.aria-label="Udskriv kvittering" (click)="udskriv()">
      Udskriv
    </button>
    <button type="button" class="btn btn-primary" attr.aria-label="Luk kvittering" (click)="lukKvittering()">
      Luk
    </button>
  </div>
</ng-template>

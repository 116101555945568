import { Pipe, PipeTransform } from '@angular/core';

import { DawaAktuelAdresse } from '../dawa-aktuel-adresse';

@Pipe({
  name: 'dawaadresse'
})
export class DawaAdressePipe implements PipeTransform {

  constructor() {}

  transform(adresse: DawaAktuelAdresse): string {
    return [adresse.vejnavn, adresse.husnr, adresse.etage, adresse.dør,
      ',', adresse.postnr, adresse.postnrnavn].join(' ');
  }
}

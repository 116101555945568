import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kvittering-filer',
  templateUrl: './kvittering-filer.component.html',
  styleUrls: ['./kvittering-filer.component.scss'],
})
export class KvitteringFilerComponent {

  @Input() filer: any

}

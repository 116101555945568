import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gdpr-laes-mere',
  templateUrl: './gdpr-laes-mere.component.html',
  styleUrls: ['./gdpr-laes-mere.component.scss']
})
export class GdprLaesMereComponent {

  constructor() { }

}

<div *ngIf="[tilstandEnum.Viser, tilstandEnum.Sender].indexOf(tilstand) !== -1">
<div *ngIf="lokationer.length !== 0">
  <div *ngIf="!indlaeser && potentielRejsestraekning">
    <div class="row">
      <div class="col-12">
        <div class="float-start">{{ potentielRejsestraekning.institution.navn }} </div>
        <div class="float-end small">
          For perioden {{ potentielRejsestraekning.fra | langdato }} - {{potentielRejsestraekning.til | langdato }}
        </div>
      </div>
    </div>
    <div class="row">
      <div *ngIf="lokationer.length > 1" class="col-12 pt-3">
        <label for="valgtLokation">Vælg uddannelsessted:</label>
      </div>
      <div *ngIf="lokationer.length === 1" class="col-12">
        {{ valgtLokation.navn }} {{ valgtLokation.adresse | adresse }}
      </div>
    </div>
    <div *ngIf="lokationer.length !== 1">
      <div *ngFor="let lokation of lokationer; let i = index" class="form-check">
        <div class="row">
          <div class="col-12">
            <input
                class="form-check-input"
                type="radio"
                (click)="updateRejsetid(i)"
                name="valgtLokation"
                value="{{ i + 1 }}"
                [(ngModel)]="valgtLokationIndex"
                id="valg{{ i + 1 }}"
              />
            <label class="form-check-label" for="valg{{i+1}}">
              {{ lokation.navn }} {{ lokation.adresse | adresse }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div *ngIf="this.rejsetidFraBopaelTilLokation">
     <div class="row">
      <div class="col-12">
        <div *ngIf="this.rejsetidFraBopaelTilLokation ">
          <b>{{ this.rejsetidFraBopaelTilLokation}}</b>
        </div>
          Angiv, hvor lang din daglige transporttid er fra bopæl til uddannelsessted.
          Du bekræfter transporttiden under strafansvar.
      </div>
     </div>
     <div class="form-check">
      <div class="row">
        <div class="col-12">
          <input
                class="form-check-input"
                type="radio"
                name="troOgLoveRejsetid"
                value="J"
                [(ngModel)]="troOgLoveRejsetid"
                id="troOgLoveRejsetid_under"
              />
          <label class="form-check-label" for="troOgLoveRejsetid_under">
            under eller præcis 3½ time hver vej
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <input
            class="form-check-input"
            type="radio"
            name="troOgLoveRejsetid"
            value="N"
            [(ngModel)]="troOgLoveRejsetid"
            id="troOgLoveRejsetid_over"
          />
          <label class="form-check-label" for="troOgLoveRejsetid_over">
            over 3½ time hver vej
          </label>
        </div>
      </div>
     </div>
     <div class="row">
      <div class="col-12">Vi kan foretage kontrol af rejsetid.</div>
    </div>

     <div class="row">
      <div class="col-12 ">
        Læs mere om <a href="https://www.ungdomskort.dk/12522" target="_blank">betingelserne for rejsetid og Ungdomskort her.</a>
      </div>
     </div>
    </div>

    <div *ngIf="!this.rejsetidFraBopaelTilLokation ">
      <div class="row">
       <div class="col-12">
           Angiv, hvor lang din daglige transporttid er fra bopæl til uddannelsessted.
           Du bekræfter transporttiden under strafansvar.
           Vi registrerer din rejsetid via opslag i rejseplanen og kan foretage kontrol.
       </div>
      </div>
      <div class="form-check">
       <div class="row">
         <div class="col-12">
           <input
             class="form-check-input"
             type="radio"
             name="troOgLoveRejsetid"
             value="J"
             [(ngModel)]="troOgLoveRejsetid"
             id="troOgLoveRejsetid_under"
           />
           <label class="form-check-label" for="troOgLoveRejsetid_under">
             under eller præcis 3½ time hver vej
           </label>
         </div>
       </div>
       <div class="row">
         <div class="col-12">
           <input
             class="form-check-input"
             type="radio"
             name="troOgLoveRejsetid"
             value="N"
             [(ngModel)]="troOgLoveRejsetid"
             id="troOgLoveRejsetid_over"
           />
           <label class="form-check-label" for="troOgLoveRejsetid_over">
             over 3½ time hver vej
           </label>
         </div>
       </div>
      </div>

      <app-rejsetid-laes-mere></app-rejsetid-laes-mere>
     </div>

    <app-gdpr-checkbox (gdprLaest$)="gdpr = $event"></app-gdpr-checkbox>

    <div class="row">
      <div class="col-12 mt-3">
        <button
            (click)="godkend()"
            [disabled]="!valgtLokationIndex || tilstand === tilstandEnum.Sender || !gdpr || !troOgLoveRejsetid"
            type="submit"
            class="btn btn-primary"
            attr.aria-label="Godkend adresse for dit uddannelsessted"
          >
            Godkend
          </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!indlaeser && (indskrivningMangler$ | async)">
  <p>
    Vi har ikke registreret, at du går på eller skal gå på en uddannelse, der er godkendt.
    Du skal derfor henvende dig på dit uddannelsessted.
  </p>
  <p>
    Læser du i udlandet, kan du tilføje din uddannelse ved at trykke på 'Tilføj udenlandsk uddannelsessted'.
  </p>
</div>
</div>

<ng-template #kvittering>
  <div class="modal-header bg-primary text-white">
    <h3 class="modal-title text-white">Kvittering</h3>
    <button type="button" class="close pull-right" aria-label="Luk kvittering" (click)="dialogRef.hide()">
      <span aria-hidden="true" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4 class="py-3">
      Du er nu godkendt til at <u>bestille</u> et Ungdomskort hos trafikselskaberne i Mit Ungdomskort
    </h4>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12 pb-2"><b>Fra din bopæl</b></div>
          <div class="col-12">{{ conavn }}</div>
          <div class="col-12">{{ bopaelAdresse | adresse:1 }}</div>
          <div class="col-12">{{ bopaelAdresse | adresse:2 }}</div>
          <div class="col-12">{{ bopaelAdresse | adresse:3 }}</div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="col-12 pb-2"><b>Til dit uddannelsessted</b></div>
        <div class="col-12">{{ institutionsnavn }}</div>
        <div class="col-12">{{ valgtLokation.navn }}</div>
        <div class="col-12">{{ valgtLokation.adresse | adresse:1 }}</div>
        <div class="col-12">{{ valgtLokation.adresse | adresse:2 }}</div>
        <div class="col-12">{{ valgtLokation.adresse | adresse:3 }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <button
          (click)="fortsaetTilTrin2SikkertMedLogAf()"
          type="submit"
          class="btn btn-primary d-block w-100"
          attr.aria-label="Log ud af NemLog-in og fortsæt til trin2 mitungdomskort.dk hos trafikselskaberne"
        >
          Bestil Ungdomskort
        </button>
      </div>
      <div class="col-12 text-center pt-2">
        <a href="#" (click)="lukKvittering()" attr.aria-label="Luk kvittering"><small>Luk vinduet</small></a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #afslag>
  <div class="modal-header bg-primary text-white">
    <h3 class="modal-title text-white">Afslag</h3>
    <button type="button" class="close pull-right" aria-label="Luk kvittering" (click)="dialogRefAfslag.hide()">
      <span aria-hidden="true" class="text-white">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4 class="py-3">Du er ikke berettiget til Ungdomskort</h4>
    <p>Du har søgt om godkendelse til Ungdomskort.</p>
    <p>Vi har afgjort at du ikke er berettiget til Ungdomskort, fordi din daglige rejsetid er over 3½ timer hver vej.</p>
    <p>
      Vi henviser til §1 i bekendtgørelse om befordringsrabat til uddannelsessøgende i ungdomsuddannelser eller til §2
      i bekendtgørelse om befordringsrabat til studerende ved videregående uddannelser.
    </p>
    <p>
      På <a href="http://www.ungdomskort.dk" target="_blank">www.ungdomskort.dk</a> kan du læse om muligheden for at
      søge dispensation, hvis du kan dokumentere, at du reelt rejser dagligt over 3½ time hver vej.
    </p>
    <div class="row">
      <div class="col-12 text-center pt-2">
        <a href="#" (click)="lukKvitteringAfslag()" attr.aria-label="Luk kvittering"><small>Luk vinduet</small></a>
      </div>
    </div>
  </div>
</ng-template>

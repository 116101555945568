// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { KeycloakConfig } from 'keycloak-js';

// Keycloak configuration
const keycloakConfig: KeycloakConfig = {
  url: '',
  realm: '',
  clientId: ''
};

export const environment = {
  production: false,
  demo: false,
  trin2Url: '',
  keycloak: keycloakConfig,
  fuldmagtsprivilege: 'urn:dk:umit:minsu:privilege_ungdomskort',
  ukApi: '/api',
  userTracking: {
    brug: 'ga',
    ga:  {
      ejendom: 'UA-11016073-13'
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<form [formGroup]="form" class="mt-3">
  <div class="row">
    <div class="mb-3 col-12 col-sm-6 col-lg-3">
          <label for="landekode" attr.aria-label="{{ariaLabelLandekode}}">* Land</label>
          <div class="mt-2"></div>
          <select id="landekode" name="landekode" required class="form-control" formControlName="landekode" #landekode>
            <option *ngIf="!skjulLandekodeDK" value="DK">Danmark</option>
            <option value="SE">Sverige</option>
            <option value="DE">Tyskland</option>
          </select>
    </div>

    <div *ngIf="brugerDawa" class="col-12 pb-3">
      <label for="adresseLinje">* {{ adresselabel }}</label>
      <div class="mt-2"></div>
      <input
        id="adresseLinje"
        autocomplete="off"
        [typeahead]="adresseLinje$"
        (typeaheadOnSelect)="dawaAdresseValgt($event)"
        [typeaheadOptionsLimit]="7"
        typeaheadOptionField="forslagstekst"
        placeholder="Indtast og vælg adresse"
        class="form-control"
        aria-label="Indtast adresse med vejnavn, husnummer, postnummer og bynavn"
        formControlName="adresseLinje"
      />
      <div *ngIf="typeaheadLoading">Loading</div>
      <div *ngIf="visManuelIndtastningLink" class="ufm-orange text-dark p-1">
        <b>
        Vælg
        <a href="#" (click)="brugManuelIndtastning()" aria-label="Manuel indtastning af adresse uden brug af automatiske adresseforslag">
          manuel indtastning af dansk adresse
        </a>
        hvis du ikke kan finde den ønskede adresse.
        </b>
      </div>
    </div>

    <div class="col-12">
      <div *ngIf="adresse.flytning" class="form-check mb-3">
        <input id="skolehjem" name="skolehjem" type="checkbox" class="form-check-input" formControlName="skolehjem" />
        <label for="skolehjem" class="form-check-label">Jeg flytter på skolehjem</label>
      </div>
    </div>

    <div class="col-12">
      <div *ngIf="adresse.flytning" class="form-check mb-3">
        <input id="adressebeskyttelse" name="adressebeskyttelse" type="checkbox" class="form-check-input" formControlName="adressebeskyttelse" />
        <label for="adressebeskyttelse" class="form-check-label">Jeg har adresse&shy;beskyttelse</label>
      </div>
    </div>

    <div *ngIf="adresse.flytning" class="mb-3 col-12 col-sm-6 col-lg-4">
      <label for="conavn" aria-label="co navn. Bruges hvis dit navn ikke står på postkassen">c/o-navn (eventuelt)</label>
      <div class="mt-2"></div>
      <input id="conavn" name="conavn" type="text" class="form-control" formControlName="conavn" />
    </div>
    <div *ngIf="adresse.praktik" class="mb-3 col-12 col-sm-6 col-lg-4">
      <label for="praktikstedNavn">* Navn på praktiksted</label>
      <div class="mt-2"></div>
      <input id="praktikstedNavn" name="praktikstedNavn" type="text" class="form-control" formControlName="praktikstedNavn" />
      <div *ngIf="form.controls.praktikstedNavn.touched && form.controls.praktikstedNavn.errors" class="alert alert-danger">
        <div *ngIf="form.controls.praktikstedNavn.errors.required">
          Navn på praktiksted mangler
        </div>
      </div>
    </div>
    <div *ngIf="adresse.udlandUddSted || adresse.ekstraUddsted" class="mb-3 col-12 col-sm-6 col-lg-4">
      <label for="uddstedNavn">* Navn på uddannelsessted</label>
      <div class="mt-2"></div>
      <input id="uddstedNavn" name="uddstedNavn" type="text" class="form-control" formControlName="uddstedNavn" />
      <div *ngIf="form.controls.uddstedNavn.touched && form.controls.uddstedNavn.errors" class="alert alert-danger">
        <div *ngIf="form.controls.uddstedNavn.errors.required">
          Navn på uddannelsessted mangler
        </div>
      </div>
    </div>

    <div class="w-100 d-none d-lg-block"></div>

    <div *ngIf="!brugerDawa" class="mb-3 col-12 col-lg-4">
      <label for="vejnavn" aria-label="Gadenavn eller vejnavn">* Gade / Vej</label>
      <div class="mt-2"></div>
      <input id="vejnavn" name="vejnavn" type="text" required class="form-control" formControlName="vejnavn" />
      <div *ngIf="form.controls.vejnavn.touched && form.controls.vejnavn.errors" class="alert alert-danger">
        <div *ngIf="form.controls.vejnavn.errors.required">
          Gade / Vej mangler
        </div>
      </div>
    </div>

    <div *ngIf="!brugerDawa" class="mb-3 col-4 col-lg-2">
      <label for="husnr" aria-label="Husnummer">* Nr.</label>
      <div class="mt-2"></div>
      <input id="husnr" name="husnr" type="text" required class="form-control" formControlName="husnr"/>
      <div *ngIf="form.controls.husnr.touched && form.controls.husnr.errors" class="alert alert-danger">
        <div *ngIf="form.controls.husnr.errors.required">
          Husnr. mangler
        </div>
      </div>
    </div>

    <div *ngIf="!brugerDawa" class="mb-3 col-4 col-lg-2">
      <label for="etage">Etage</label>
      <div class="mt-2"></div>
      <input id="etage" name="etage" type="text" class="form-control" formControlName="etage" />
    </div>

    <div *ngIf="!brugerDawa" class="mb-3 col-4 col-lg-2">
      <label for="sidedoer" aria-label="Side eller dørnummer">Side / Dør nr.</label>
      <div class="mt-2"></div>
      <input id="sidedoer" name="sidedoer" type="text" class="form-control" formControlName="sidedoer" />
    </div>

    <div class="w-100 d-none d-lg-block"></div>

    <div *ngIf="!brugerDawa" class="mb-3 col-4 col-lg-2">
      <label for="postnr" aria-label="postnummer">* Postnr.</label>
      <div class="mt-2"></div>
      <input id="postnr" name="postnr" type="text" required class="form-control" formControlName="postnr" />
      <div *ngIf="form.controls.postnr.touched && form.controls.postnr.errors" class="alert alert-danger">
        <div *ngIf="form.controls.postnr.errors.required">
          Postnummer mangler
        </div>
        <div *ngIf="form.controls.postnr.errors.minlength || form.controls.postnr.errors.maxlength">
          <div *ngIf="form.controls.landekode.value === 'DK'">
            Postnummer skal være på 4 tegn (Danmark)
          </div>
          <div *ngIf="form.controls.landekode.value !== 'DK'">
            Postnummer skal være på 5 tegn (Sverige og Tyskland)
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!brugerDawa" class="mb-3 col-8 col-lg-4">
      <label for="bynavn" aria-label="Bynavn">* By</label>
      <div class="mt-2"></div>
      <input id="bynavn" name="bynavn" type="text" required class="form-control" formControlName="bynavn" />
      <div *ngIf="form.controls.bynavn.touched && form.controls.bynavn.errors" class="alert alert-danger">
        <div *ngIf="form.controls.bynavn.errors.required">
          Bynavn mangler
        </div>
      </div>
    </div>

    <div *ngIf="!brugerDawa && adresse.flytning && form.controls.landekode.value === 'DK'" class="mb-3 col-6 col-lg-2">
      <label for="kommunekode">* Kommune</label>
      <div class="mt-2"></div>
      <select id="kommunekode" name="kommunekode" class="form-control" formControlName="kommunekode">
        <option></option>
        <option *ngFor="let k of kommuner" [ngValue]="k.kode">{{ k.navn }}</option>
      </select>
      <div *ngIf="form.controls.kommunekode.touched && form.controls.kommunekode.errors" class="alert alert-danger">
        <div *ngIf="form.controls.kommunekode.errors.required">
          Kommune mangler
        </div>
        <div *ngIf="form.controls.kommunekode.errors.min || form.controls.kommunekode.errors.max">
          Kommune skal være på 3 cifre (101 til 860)
        </div>
      </div>
    </div>

    <div class="w-100 d-none d-lg-block"></div>

    <div *ngIf="adresse.flytning" class="mb-3 col-6 col-lg-3">
      <label for="flyttedato">* Jeg flytter den</label>
      <div class="mt-2"></div>
      <input
        id="flyttedato"
        name="flyttedato"
        type="text"
        placeholder="dd-mm-yyyy"
        required
        class="form-control"
        formControlName="flyttedato"
        autocomplete="off"
        bsDatepicker
        [minDate]="minStartDato"
        [maxDate]="maxStartDatoFlytning"
        placement="top"
        (onHidden)="visesDatovaelger = false"
        (onShown)="visesDatovaelger = true"
        (bsValueChange)="datoBlevValgt()"
      />
      <div *ngIf="form.controls.flyttedato.touched && !visesDatovaelger && form.controls.flyttedato.errors" class="alert alert-danger">
        <div *ngIf="form.controls.flyttedato.errors.required">
          Flyttedato mangler
        </div>
        <div *ngIf="form.controls.flyttedato.errors.bsDate">
          Ugyldig flyttedato
        </div>
      </div>
    </div>

    <div *ngIf="adresse.praktik" class="mb-3 col-12">
      Angiv perioden, hvor du er i praktik:
    </div>
    <div *ngIf="adresse.ekstraUddsted" class="mb-3 col-12">
      Angiv perioden, hvor du modtager undervisning på dette uddannelsessted:
    </div>
    <div *ngIf="adresse.praktik || adresse.ekstraUddsted" class="mb-3 col-6 col-lg-3">
      <label for="fra">* Fra dato</label>
      <div class="mt-2"></div>
      <input
        id="fra"
        name="fra"
        type="text"
        placeholder="dd-mm-yyyy"
        required
        class="form-control"
        formControlName="fra"
        autocomplete="off"
        bsDatepicker
        [minDate]="minStartDato"
        [maxDate]="maxStartDato"
        placement="top"
        (onHidden)="visesDatovaelger = false"
        (onShown)="visesDatovaelger = true"
        (bsValueChange)="datoBlevValgt()"
      />
      <div *ngIf="form.controls.fra.touched && !visesDatovaelger && form.controls.fra.errors" class="alert alert-danger">
        <div *ngIf="form.controls.fra.errors.required">
          Fra dato mangler
        </div>
      </div>
    </div>
    <div *ngIf="adresse.praktik || adresse.ekstraUddsted" class="mb-3 col-6 col-lg-3">
      <label for="til">* Til dato</label>
      <div class="mt-2"></div>
      <input
        id="til"
        name="til"
        type="text"
        placeholder="dd-mm-yyyy"
        required
        class="form-control"
        formControlName="til"
        autocomplete="off"
        bsDatepicker
        [minDate]="minSlutDato"
        placement="top"
        (onHidden)="visesDatovaelger = false"
        (onShown)="visesDatovaelger = true"
        (bsValueChange)="datoBlevValgt()"
      />
      <div *ngIf="form.controls.til.touched && !visesDatovaelger && form.controls.til.errors" class="alert alert-danger">
        <div *ngIf="form.controls.til.errors.required">
          Til dato mangler
        </div>
      </div>
    </div>

    <div class="w-100 d-none d-lg-block"></div>
    <div *ngIf="form.errors && form.errors.ugyldigPeriode" class="col-12 col-lg-6">
      <div class="alert alert-danger">
        Ugyldig periode. Fra dato er større end til dato.
      </div>
    </div>

    <app-filer *ngIf="adresse.praktik" class="col-12" [form]="form.controls.filer" [accept]="'.pdf'"
      [idPrefix]="'praktik_fil'"
      [label]="'Her kan du uploade dokumentation for ulønnet praktik (praktikaftale) (i alt max 20MB i PDF-format)'"
      [ariaLabelTilfoejFiler]="'Tilføj flere filer vedr. praktik aftalen'"
      [ariaLabelFil]="'Upload pdf-fil med praktik aftalen'"
      (filUploaded$)="filUploaded($event)" (keydown.enter)="$event.preventDefault()">
    </app-filer>
    <app-filer *ngIf="adresse.ekstraUddsted" class="col-12" [form]="form.controls.filer" [accept]="'.pdf'"
      [idPrefix]="'uddsted_fil'"
      [label]="'Her kan du uploade dokumentation for undervisning på dette undervisningssted (i alt max 20MB i PDF-format)'"
      [ariaLabelTilfoejFiler]="'Tilføj flere filer vedr. dette undervisningssted'"
      [ariaLabelFil]="'Upload pdf-fil med dokumentation for dette undervisningssted'"
      (filUploaded$)="filUploaded($event)" (keydown.enter)="$event.preventDefault()">
    </app-filer>

    <div *ngIf="adresse.udlandUddSted" class="mb-3 col-6 col-lg-3">
      <label for="fra">* Min uddannelse begynder</label>
      <div class="mt-2"></div>
      <input
        id="fra"
        name="fra"
        type="text"
        placeholder="dd-mm-yyyy"
        required
        class="form-control"
        formControlName="fra"
        autocomplete="off"
        bsDatepicker
        [minDate]="minStartDato"
        [maxDate]="maxStartDato"
        placement="top"
        (onHidden)="visesDatovaelger = false"
        (onShown)="visesDatovaelger = true"
        (bsValueChange)="datoBlevValgt()"
      />
      <div *ngIf="form.controls.fra.touched && !visesDatovaelger && form.controls.fra.errors" class="alert alert-danger">
        <div *ngIf="form.controls.fra.errors.required">
          Start dato mangler
        </div>
      </div>
    </div>

    <div class="d-none" aria-hidden="true" #labelFiluploadUudlandUddSted>
      * Her skal du uploade
      <a href="https://www.ungdomskort.dk/12667" target="_blank">dokumentation</a>
      der viser, at du går på en SU-godkendt uddannelse (i alt max 20MB i PDF-format)
    </div>
    <app-filer *ngIf="adresse.udlandUddSted" class="col-12" [form]="form.controls.filer" [accept]="'.pdf'"
      [idPrefix]="'udenlandsk_uddsted_fil'"
      [label]="labelFiluploadUudlandUddSted.innerHTML"
      [ariaLabelTilfoejFiler]="'Tilføj flere filer vedr. udenlandsk uddannelsessted'"
      [ariaLabelFil]="'Upload pdf-fil med dokumentation for udenlandsk uddannelsessted'"
      (filUploaded$)="filUploaded($event)" (keydown.enter)="$event.preventDefault()">
    </app-filer>

    <div *ngIf="adresse.praktik || adresse.ekstraUddsted || adresse.udlandUddSted" class="col-12 mt-3">
      <div class="row">
        <div *ngIf="(adresse.praktik || adresse.ekstraUddsted) && !udenlandskAdresse" class="col-6 col-lg-4">
            <button (click)="beregnRejsetid()" type="submit" class="btn btn-primary d-block w-100" aria-label="Beregn rejsetid">Beregn rejsetid</button>
        </div>
      </div>
      <div class="row"><div class="col-12"></div><br /></div>
      <div *ngIf="this.rejsetidFraBopaelTilLokation && (adresse.praktik || adresse.ekstraUddsted)" class="col-12">
      <div class="row">
        <div *ngIf="this.rejsetidFraBopaelTilLokation && (adresse.praktik || adresse.ekstraUddsted)" class="col-12">
          <b>{{ this.rejsetidFraBopaelTilLokation}}</b>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          Angiv, hvor lang din daglige transporttid er fra bopæl til uddannelsessted.
          Du bekræfter transporttiden under strafansvar.
        </div>
      </div>
      <div class="form-check">
        <div class="row">
          <div class="col-12">
            <input id="troOgLoveRejsetid_under" name="troOgLoveRejsetid" type="radio" class="form-check-input" value="J" formControlName="troOgLoveRejsetid" />
            <label class="form-check-label" for="troOgLoveRejsetid_under">
              under eller præcis 3½ time hver vej
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input id="troOgLoveRejsetid_over" name="troOgLoveRejsetid" type="radio" class="form-check-input" value="N"  formControlName="troOgLoveRejsetid" />
            <label class="form-check-label" for="troOgLoveRejsetid_over">
              over 3½ time hver vej
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">Vi kan foretage kontrol af rejsetid.</div>
      </div>

      <div class="row">
        <div class="col-12 ">
          Læs mere om <a href="www.ungdomskort.dk/12522" target="_blank">betingelserne for rejsetid og Ungdomskort her.</a>
        </div>
      </div>
    </div>

    <div *ngIf="!this.rejsetidFraBopaelTilLokation && ((this.rejsetidResult > -3 && (adresse.praktik || adresse.ekstraUddsted)) || (adresse.udlandUddSted)) || (adresse.landekode !== 'DK')" class="col-12">
      <div class="row"></div>
      <div class="row">
        <div class="col-12">
          Angiv, hvor lang din daglige transporttid er fra bopæl til uddannelsessted.
          Du bekræfter transporttiden under strafansvar.
          Vi registrerer din rejsetid via opslag i rejseplanen og kan foretage kontrol.
        </div>
      </div>
      <div class="form-check">
        <div class="row">
          <div class="col-12">
            <input id="troOgLoveRejsetid_under" name="troOgLoveRejsetid" type="radio" class="form-check-input" value="J" formControlName="troOgLoveRejsetid" />
            <label class="form-check-label" for="troOgLoveRejsetid_under">
              under eller præcis 3½ time hver vej
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <input id="troOgLoveRejsetid_over" name="troOgLoveRejsetid" type="radio" class="form-check-input" value="N"  formControlName="troOgLoveRejsetid" />
            <label class="form-check-label" for="troOgLoveRejsetid_over">
              over 3½ time hver vej
            </label>
          </div>
        </div>
      </div>

      <app-rejsetid-laes-mere></app-rejsetid-laes-mere>
    </div>
    </div>

    <app-gdpr-checkbox class="col-12" (gdprLaest$)="gdpr = $event"></app-gdpr-checkbox>
  </div>

  <div class="row">
    <div class="col-6 col-lg-4 col-xl-4 mt-5 mb-4">
      <button (click)="annullerIndtastning()" type="submit" class="btn btn-outline-secondary d-block w-100" aria-label="Annuller indtastning">
        Annuller
      </button>
    </div>
    <div class="col-6 col-lg-4 col-xl-4 mt-5">
      <button (click)="gemIndtastning()" [disabled]="(!form.valid || sender || !gdpr)" type="submit" class="btn btn-primary d-block w-100"
        attr.aria-label="{{ submitKnapTekst }}. Gem indtastning">
        {{ submitKnapTekst }}
      </button>
    </div>
  </div>
</form>

<ng-template #adressevaskResultatB>
  <div class="modal-header">
    <h4 class="modal-title">Adresse advarsel</h4>
    <button type="button" class="close pull-right" aria-label="Luk adresseforslag" (click)="dialogRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger" role="alert">
      <h4 class="alert-heading">Forslag til adresse</h4>
      <div class="row">
        <div class="col-12">
          Den indtastede {{ adressetype }} adresse <i>{{ ukendtAdresse }}</i> kan være forkert.
          I følge Danmarks Adresser (DAWA) er den korrekt {{ adressetype }} <b>{{ dawaAdresse }}</b>.
          Vær opmærksom på, at de indtastede oplysninger ikke vil blive anvendt, hvis oplysningerne ikke er korrekte.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">Du har følgende muligheder:
        <ul>
          <li>Tryk <b>Fortryd</b> og ret adresse eller brug link til manuel indtastning</li>
          <li>Tryk <b>Ret fejl</b> og brug <b>{{ dawaAdresse }}</b></li>
          <li *ngIf="!brugerDawa">Tryk <b>Fortsæt</b> og brug {{ ukendtAdresse }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" attr.aria-label="Fortryd og ret oplysninger" (click)="adresseforslagFortryd3()">
      Fortryd
    </button>
    <button type="button" class="btn btn-primary" attr.aria-label="Fortsæt og brug {{ dawakommunenavn }} adresse fra Danmarks Adresser (DAWA)"
      (click)="adresseAdvarselBrugAdresseFraDawa3()">
      Ret fejl
    </button>
    <button *ngIf="!brugerDawa" type="button" class="btn btn-danger" attr.aria-label="Fortsæt og brug {{ ukendtAdresse }} adresse fra indtastning"
      (click)="adresseAdvarselBrugUkendtAdresse3()">
      Fortsæt
    </button>
  </div>
</ng-template>

<ng-template #adressevaskResultatC>
  <div class="modal-header">
    <h4 class="modal-title">Adresseforslag</h4>
    <button type="button" class="close pull-right" aria-label="Luk adresseforslag" (click)="dialogRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Den indtastede
      <span *ngIf="adresse.praktik">praktikplads </span>
      <span *ngIf="adresse.ekstraUddsted">uddannelsessted</span>
      adresse "{{ ukendtAdresse }}" kan være forkert. Adressen findes ikke i Danmarks Adresser (DAWA).
      Din ansøgning om godkendelse af rejsestrækning vil blive afvist, hvis adressen ikke er korrekt.
    </p>
    <div class="row">
      <div class="col-12">Du har følgende:
        <ul>
          <li>Tryk <b>Fortryd</b> og ret adresse eller brug link til manuel indtastning</li>
          <li>Vælg adresse fra listen og tryk <b>Fortsæt</b></li>
          <li *ngIf="!brugerDawa">Tryk <b>Fortsæt</b> og brug ukendt adresse</li>
        </ul>
      </div>
      <div *ngFor="let forslag of adresseForslag | slice:0:visAntalAdresseForslag;  let i = index" class="col-12">
        <input type="radio" [(ngModel)]="valgtAdresseForslag" name="list_name" value="{{i}}" attr.aria-label="{{forslag.tekst + (forslag.kommunenavn ? ' ( ' + forslag.kommunenavn + ' )': '')}}" />
        {{forslag.tekst}}
        <span *ngIf="forslag.kommunenavn">( {{forslag.kommunenavn}} )</span>
      </div>
      <div *ngIf="adresseForslag.length > visAntalAdresseForslag" class="col-12">
        <a href="#" (click)="visFlereAdresseForslag3()">Vis flere adresseforslag</a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" aria-label="Fortryd og ret ukendt adresse" (click)="adresseforslagFortryd3()">
      Fortryd
    </button>
    <button type="button" class="btn btn-primary" attr.aria-label="Fortsæt med ukendt adresse {{ adresse | adresse }}"
      [disabled]="disableAdresseforslagFortsaet3()" (click)="adresseforslagFortsaet3()">
      Fortsæt
    </button>
  </div>
</ng-template>

<ng-template #kommuneforslag>
  <div class="modal-header">
    <h4 class="modal-title">Kommune advarsel</h4>
    <button type="button" class="close pull-right" aria-label="Luk adresseforslag" (click)="dialogRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger" role="alert">
      <h4 class="alert-heading">Forslag til kommune</h4>
      Den indtastede flytteadresse {{ adresse | adresse }} kan være forkert.
      Du har opgivet, at du flytter til <i>{{ kommuneValgtAfBruger }}</i>.
      I følge Danmarks Adresser (DAWA) tilhører adressen <b>{{ dawakommunenavn }}</b>.
      De indtastede flytteoplysninger vil ikke blive brugt, hvis oplysningerne ikke er korrekte.
    </div>
    <div class="row">
      <div class="col-12">Du har følgende muligheder:
        <ul>
          <li>Tryk <b>Fortryd</b> og ret adresse eller brug link til manuel indtastning</li>
          <li>Tryk <b>Ret fejl</b> og brug <b>{{ dawakommunenavn }}</b></li>
          <li>Tryk <b>Fortsæt</b> og brug {{ kommuneValgtAfBruger }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" attr.aria-label="Fortryd og ret oplysninger" (click)="kommuneforslagFortryd5()">
      Fortryd
    </button>
    <button type="button" class="btn btn-primary" attr.aria-label="Fortsæt og brug {{ dawakommunenavn }} kommune fra Danmarks Adresser (DAWA)"
      (click)="kommuneforslagBrugKommuneFraDawa5()">
      Ret fejl
    </button>
    <button type="button" class="btn btn-danger" attr.aria-label="Fortsæt og brug {{ kommuneValgtAfBruger }} kommune fra indtastning"
      (click)="kommuneforslagBrugKommuneValgtAfBruger5()">
      Fortsæt
    </button>
  </div>
</ng-template>

import { Injectable } from '@angular/core';

import { AdresseForm } from './adresse-form/adresse-form';
import { Flytning } from './flytning';
import { Praktik } from '../../forretnings-sider/praktikplads/praktik';
import { EkstraUddsted } from '../../forretnings-sider/ekstra-uddannelsessted/ekstra-uddsted';
import { UdlandUddsted } from '../../forretnings-sider/udenlandsk-uddannelsessted/udland-uddsted';
import { Adresse } from './adresse';
import { Fil } from './fil';

@Injectable()
export class ApiFormService {

  initialiserForm(adresse: Flytning | Praktik | EkstraUddsted | UdlandUddsted): AdresseForm {
    return new AdresseForm(adresse);
  }

  getAdresseJson(adresse: AdresseForm): any {
    return {
      landekode: adresse.landekode,
      postnr: adresse.landekode === 'DK' ? adresse.postnr : '',
      bynavn: adresse.bynavn,
      vejnavn: adresse.vejnavn,
      husnr: adresse.husnr,
      etage: adresse.etage,
      sidedoer: adresse.sidedoer,
      zipcode: adresse.landekode === 'DK' ? '' : adresse.postnr,
      filer: adresse.filer
    };
  }

  getFlytning(adresse: AdresseForm): Flytning {
    const flytning = new Flytning({
      adresse: this.getAdresseJson(adresse),
      conavn: adresse.flytning.conavn,
      flyttedato: adresse.flytning.flyttedato,
      adressebeskyttelse: adresse.flytning.adressebeskyttelse,
      skolehjem: adresse.flytning.skolehjem,
      skolehjemNavn: adresse.flytning.skolehjemNavn,
      kommunekode: adresse.flytning.kommunekode
    });
    return flytning;
  }

  getPraktik(adresse: AdresseForm): Praktik {
    return new Praktik({
      adresse: this.getAdresseJson(adresse),
      til: adresse.praktik.til,
      fra: adresse.praktik.fra,
      stedNavn: adresse.praktik.stedNavn,
      rejsetid: adresse.praktik.rejsetid,
      troOgLoveRejsetid: adresse.praktik.troOgLoveRejsetid
    });
  }

  getUdland(adresse: AdresseForm): UdlandUddsted {
    return new UdlandUddsted({
      adresse: this.getAdresseJson(adresse),
      fra: adresse.udlandUddSted.fra,
      stedNavn: adresse.udlandUddSted.stedNavn,
      rejsetid: adresse.udlandUddSted.rejsetid,
      troOgLoveRejsetid: adresse.udlandUddSted.troOgLoveRejsetid
    });
  }

  getEkstraUddsted(adresse: AdresseForm): EkstraUddsted {
    return new EkstraUddsted({
      adresse: this.getAdresseJson(adresse),
      til: adresse.ekstraUddsted.til,
      fra: adresse.ekstraUddsted.fra,
      stedNavn: adresse.ekstraUddsted.stedNavn,
      rejsetid: adresse.ekstraUddsted.rejsetid,
      troOgLoveRejsetid: adresse.ekstraUddsted.troOgLoveRejsetid
    });
  }

  // konverter AdresseForm til FormData. 
  // dvs. adresse konverteres til json og sendes som en application/json fil API
  // samt alle vedlagte pdf-filer tilføjes til formdata før submit 
  private ansoegningStingUdenFiler(objekt: Flytning | EkstraUddsted | UdlandUddsted): string {
    const objektJson:any = JSON.parse(JSON.stringify(objekt));
    delete objektJson.adresse['fil'];
    return JSON.stringify(objektJson);
  }

  private ansoegningBlob(objekt: Flytning | EkstraUddsted | UdlandUddsted): Blob {
    const ansoegningString: string =  this.ansoegningStingUdenFiler(objekt);
    return new Blob([ansoegningString], { type: 'application/json' });
  }

  ansoegningFormData(objekt: Flytning | EkstraUddsted | UdlandUddsted): FormData {
    const formData = new FormData();
    // vedlæg ansøgning
    const ansoegningBlob: Blob = this.ansoegningBlob(objekt);
    formData.append('ansoegning', ansoegningBlob);
    // vedlæg pdf-filer
    const adresse: Adresse = objekt.adresse;
    const filer: Fil[] = adresse.filer;
    for (let i = 0 ; i < filer.length ; i++) {
      const fil: Fil = filer[i];
      if (fil.filnavn && fil.data) {
        formData.append('dokument', fil.data, fil.filnavn);
      }
    }
    return formData;
  }
  
}

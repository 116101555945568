import { Component, OnInit, ViewChild, ElementRef, DoCheck } from '@angular/core';

import { UfmBackendtid2Config } from './ufm-backendtid2.config';
import { UfmBackendtid2ConfigService } from './ufm-backendtid2-config.service';
import { UfmBackendtid2Service } from './ufm-backendtid2.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ufm-side-backendtid',
  templateUrl: './ufm-backendtid2.component.html',
  styleUrls: ['./ufm-backendtid2.component.scss']
})
export class UfmBackendtid2Component implements OnInit, DoCheck {

  hentet: any; // Moment;
  @ViewChild('backendtidHentet', { static: false }) backendtidHentet!: ElementRef;
  private erUfmModulTeksterOpdateret = false;

  constructor(private ufmBackendtid2Service: UfmBackendtid2Service, private ufmBackendtid2ConfigService: UfmBackendtid2ConfigService) { }

  ngOnInit() {
    this.ufmBackendtid2Service.hentBackendtidForAgerendeBruger().subscribe(
      backendTid => {
        if (!backendTid) {
          this.hentet = null;
          return;
        }
        this.hentet = backendTid;
      }
    );
    this.ufmBackendtid2ConfigService.backendtid2KonfigurationOpdateret$.subscribe(opdateret => {
      if (opdateret) {
        this.opdaterLabelPgaOpdateretKonfiguration();
      }
    });
  }

  ngDoCheck(): void {
    if (this.erUfmModulTeksterOpdateret || !this.backendtidHentet) {
      return;
    }
    this.erUfmModulTeksterOpdateret = true;
    this.opdaterLabelPgaOpdateretKonfiguration();
  }

  private opdaterLabelPgaOpdateretKonfiguration(): void {
    const ufmBackendtid2Config: UfmBackendtid2Config = this.ufmBackendtid2ConfigService.getBackendtid2Config();
    if (ufmBackendtid2Config && ufmBackendtid2Config.tekster && ufmBackendtid2Config.tekster.hentet) {
      this.backendtidHentet.nativeElement.innerText = ufmBackendtid2Config.tekster.hentet;
    }
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, map, concatMap, count } from 'rxjs/operators';

import { Adresse } from '../../fælles-utils/adresse/adresse';
import { EkstraUddsted } from '../ekstra-uddannelsessted/ekstra-uddsted';
import { environment } from '../../../environments/environment';
import { DawaAdresseService } from '../../fælles-utils/adresse/dawa/dawa-adresse.service';
import { DawaPostnummerResultat } from '../../fælles-utils/adresse/dawa/dawa-postnummer-resultat';
import { GodkendtRejsestraekning } from './godkendt-rejsestraekning';
import { PotentielRejsestraekning } from './potentiel-rejsestraekning';
import { Praktik } from '../praktikplads/praktik';
import { Rejsestraekning } from './rejsestraekning';
import { SlettetRejsestraekning } from './slettet-rejsestraekning';
import { UddannelsesLokation } from '../../fælles-utils/adresse/uddannelses-lokation';
import { UdlandUddsted } from '../udenlandsk-uddannelsessted/udland-uddsted';
import { ApiFormService } from '../../fælles-utils/adresse/api-form.service';

@Injectable()
export class RejsestraekningService {

  rejsestraekninger$ = new BehaviorSubject<Rejsestraekning[]>([]);

  rejsestraekningerDerKanSlettes$: Observable<Rejsestraekning[]> = this.rejsestraekninger$
    .pipe(
      map(
        (rejsestraekninger) => rejsestraekninger.filter((rejsestrakning) => rejsestrakning.kanSlettes)
      )
    );
  visPraktikpladsSide$: Observable<boolean> = this.rejsestraekningerDerKanSlettes$
    .pipe(
      map((rejsestraekninger) => {
        const visPraktikplads = rejsestraekninger.length > 0;
        return visPraktikplads;
      })
    );
  potentielRejsestraekning$ = new BehaviorSubject<PotentielRejsestraekning>(null);
  indskrivningMangler$: Observable<boolean> = combineLatest(this.rejsestraekningerDerKanSlettes$, this.potentielRejsestraekning$)
    .pipe(
      map((data) => {
          const rejsestraekningerDerKanSlettes: Rejsestraekning[] = data[0];
          const potentielRejsestraekning: PotentielRejsestraekning = data[1];
          if (rejsestraekningerDerKanSlettes.length) {
            return false;
          } else if (!potentielRejsestraekning) {
            return true ;
          } else if (!potentielRejsestraekning.lokationer) {
            return true ;
          } else if (!potentielRejsestraekning.lokationer.length) {
            return true ;
          } else {
            return false;
          }
      })
    );
    rejsetid: number;

  constructor(private http: HttpClient, private adresseService: DawaAdresseService, private apiFormService: ApiFormService) { }

  private getRejsestraekninger(): Observable<Rejsestraekning[]> {
    return this.http.get<Rejsestraekning[]>(environment.ukApi + '/rejsestraekninger');
  }

  hentRejsestraekninger(): void {
    this.getRejsestraekninger()
      .subscribe((rejsestraekninger) => {
        // tilføj conavn til lokation
        for (let i = 0; i < rejsestraekninger.length; i++) {
          let lokation: UddannelsesLokation = rejsestraekninger[i].lokation; 
          if (lokation.conavn) {
            lokation.navn += ', c/o ' + lokation.conavn;
          }
        }
        this.rejsestraekninger$.next(rejsestraekninger);
      });
  }

  private getPotentielRejsestraekning(): Observable<PotentielRejsestraekning> {
    return this.http
      .get<PotentielRejsestraekning>(environment.ukApi + '/rejsestraekninger/potentiel', { headers: { NoInterceptorTimeout: 'x' } })
      .pipe(
        concatMap(
          (potentielRejsestraekning) => {
            if (!potentielRejsestraekning) {
              return of(potentielRejsestraekning);
            }
            return this.adresseService.hentPostnumre()
              .pipe(
                // bugfix: tilføj manglende lokation bynavn fra DAWA
                // todo: problemet med manglende bynavn bør på længere sigt løses i lokationsregistret og ikke via DAWA
                map((postnumreFraDawa) => {
                    let adresse: Adresse;
                    let postnummer: DawaPostnummerResultat;
                    for (let i = 0; i < potentielRejsestraekning.lokationer.length; i++) {
                      adresse = potentielRejsestraekning.lokationer[i].adresse;
                      if (!adresse.bynavn) {
                        postnummer = postnumreFraDawa
                          .filter((postnummerFraDawa) => postnummerFraDawa.nr === adresse.postnr)
                          .shift();
                        if (postnummer) {
                          adresse.bynavn = postnummer.navn;
                        }
                      }
                    }
                    return potentielRejsestraekning;
                })
              );
        }),
        // fjern institution.navn fra lokation.navn + tilføj conavn
        map((potentielRejsestraekning) => {
          if (!potentielRejsestraekning) {
            return potentielRejsestraekning;
          }
          const wildcard = '[,\\-\\s]*';
          let re: RegExp;
          try {
            re = new RegExp(wildcard + potentielRejsestraekning.institution.navn + wildcard, 'g');
          } catch (e) {
            return potentielRejsestraekning;
          }
          for (let i = 0; i < potentielRejsestraekning.lokationer.length; i++) {
            const lokation: UddannelsesLokation = potentielRejsestraekning.lokationer[i];
            lokation.navn = lokation.navn.replace(re, '');
            if (lokation.conavn) {
              lokation.navn += ', c/o ' + lokation.conavn;
            }
          }
          return potentielRejsestraekning;
        })
      );
  }

  hentPotentielRejsestraekning(): void {
    this.getPotentielRejsestraekning()
      .subscribe((potentielRejsestraekning) => {
        this.potentielRejsestraekning$.next(potentielRejsestraekning);
      });
  }

  godkendtRejsestraekning(godkendtRejsestreakning: GodkendtRejsestraekning): Observable<any> {
    return this.http
      .put<void>(environment.ukApi + '/rejsestraekninger/godkend', godkendtRejsestreakning)
      .pipe(
        tap(() => this.hentPotentielRejsestraekning()),
        tap(() => this.hentRejsestraekninger())
      );
  }

  sletRejsestraekning(slettetRejsestreakning: SlettetRejsestraekning): Observable<any> {
    return this.http
      .put<void>(environment.ukApi + '/rejsestraekninger/slet', slettetRejsestreakning)
      .pipe(
        tap(() => this.hentPotentielRejsestraekning()),
        tap(() => this.hentRejsestraekninger())
      );
  }

  opretPraktik(praktik: Praktik): Observable<any> {
    const formData: FormData = this.apiFormService.ansoegningFormData(praktik);
    return this.http
      .post<void>(environment.ukApi + '/rejsestraekninger/praktik', formData)
      .pipe(tap(() => this.hentRejsestraekninger()));
  }

  opretUdland(udland: UdlandUddsted): Observable<any> {
    const formData: FormData = this.apiFormService.ansoegningFormData(udland);
    return this.http
      .post<void>(environment.ukApi + '/rejsestraekninger/udenlandskuddannelsessted', formData)
      .pipe(tap(() => this.hentRejsestraekninger()));
  }

  opretEkstraUddsted(uddsted: EkstraUddsted): Observable<any> {
    const formData: FormData = this.apiFormService.ansoegningFormData(uddsted);
    return this.http
      .post<void>(environment.ukApi + '/rejsestraekninger/ekstrauddannelsessted', formData)
      .pipe(tap(() => this.hentRejsestraekninger()));
  }

  getRejsetid(adresse: Adresse): Observable<any> {
    return this.http.post<number>(environment.ukApi + '/rejsestraekninger/rejsetid', adresse, { headers: { NoInterceptorTimeout: 'x' } });
  }
}

import { InjectionToken } from '@angular/core';

export const UFM_BACKENDTID2_CONFIG = new InjectionToken<UfmBackendtid2Config>('UFM_BACKENDTID2_CONFIG');

// default tekster findes i html templates. Alternative tekster kan injectes på 3 måder:
// 1) statisk/byg: brug i18n tags. Bruges i ligestilling. Se messages.en.xlf.
// 2) statisk/byg: UfmBackendtid2Module.forRoot(ufmBackendtid2Config) fra app.module.ts
// 3) dynamisk: opdater ufmBackendtid2Config dynamisk efter opstart og kald ufmBackendtid2ConfigService.genindlaesOpdateretKonfiguration når tekster er blevet opdateret
export interface UfmBackendtid2Config {
    environment?: {
        apiPropertyNavn?: string; // api = environment.api. Dvs. navn på api property i environment.json filen
        endpoint?: string // backendtid. <api><endpoint> giver det API endpoint hvorfra backendtid hentes, fx /api/backendtid
    };
    tekster?: {
        hentet?: string; // // Hentet: i18n=@@ufmit backendtid2 hentet
    };
}

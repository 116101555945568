import { Component, OnInit } from '@angular/core';
import { UdskrivService } from './fælles-utils/udskriv-kvittering/udskriv.service';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  noprint$: Observable<string>;
  priv: string = environment.fuldmagtsprivilege ;

  constructor(private udskrivService: UdskrivService) {}

  ngOnInit() {
    this.noprint$ = this.udskrivService.noprint$;
  }
}

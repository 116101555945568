import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';
import { GdprService } from './gdpr.service';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent {

  gdpr: boolean;

  constructor(
    private router: Router,
    private userTracking: UfmUserTrackingService,
    private gdprService: GdprService) {
  }

  godkend(): void {
    this.gdprService.godkendGdpr();
    this.userTracking.sendUrl('/gdpr-side/ja');
    this.router.navigate(['/']);
  }
}

import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { FilerService } from '../filer.service';

@Component({
  selector: 'app-fil',
  templateUrl: './fil.component.html',
  styleUrls: ['./fil.component.scss']
})
export class FilComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() index: number;
  @Input() ariaLabel: string;
  @Input() filUploaded$: EventEmitter<boolean>;

  @ViewChild('fil', { static: true }) filElement: ElementRef;

  id: string;

  constructor(private toastrService: ToastrService, private filService: FilerService) { }

  ngOnInit() {
    this.id = this.form.controls.gruppe.value + '_fil' + this.index;
  }

  uploadFil(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const filnavn: string = event.target.files[0].name;
      const filtype: string = filnavn.split('.').slice(-1)[0].toLowerCase();
      if (filtype !== 'pdf') {
        this.toastrService.error(
          'Du må kun uploade pdf-dokumenter', 'Fejl', { tapToDismiss: true, disableTimeOut: true, closeButton: true });
        this.fjernFil();
        return;
      }

      this.filService.ValiderPDF(event.target.files[0]).subscribe((data: any) => {
        if (data.validationStatus === 'ERROR') {
          this.toastrService.error(data.details, 'Fejl', { tapToDismiss: true, disableTimeOut: true, closeButton: true });
          this.fjernFil();
          return;
        }
      });

      const reader: FileReader = new FileReader();
      reader.onload = () => {
        this.form.controls.filnavn.setValue(filnavn);
        const blob: Blob = new Blob([reader.result], { type: event.target.files[0].type });
        this.form.controls.data.setValue(blob);
        this.filUploaded$.emit(true);
      };
      reader.readAsArrayBuffer(event.target.files[0]);
    } else {
      this.fjernFilnavnOgData();
    }
  }

  fjernFil(): void {
    this.form.controls.fil.setValue('');
    this.filElement.nativeElement.value = '';
    this.fjernFilnavnOgData();
  }

  private fjernFilnavnOgData() {
    this.form.controls.filnavn.setValue(null);
    this.form.controls.data.setValue(null);
    this.filUploaded$.emit(false);
  }

  fjernFilDisabled(): boolean {
    return !this.form.controls.data.value;
  }

}

import { Adresse } from '../../fælles-utils/adresse/adresse';
import { Flytning } from '../../fælles-utils/adresse/flytning';

export class Profil {
  public navn: string;
  public cpr: string;
  public adressebeskyttelse: boolean;
  public conavn: string;
  public adresse: Adresse;
  public flytning: Flytning;
  public udenlandskAdresse: boolean;

  constructor(json: any) {
    this.navn = json.navn;
    this.cpr = json.cpr;
    this.adressebeskyttelse = json.adressebeskyttelse;
    this.conavn = json.conavn;
    this.adresse = new Adresse(json.adresse);
    if (json.flytning) {
      this.flytning = new Flytning(json.flytning);
    }
    this.udenlandskAdresse = this.adresse.landekode !== 'DK' || this.flytning && this.flytning.adresse.landekode !== 'DK' ? true : false;
  }
}

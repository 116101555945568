<div class="row">
  <div class="col-12">
    <div class="form-check mt-3">
      <input id="gdpr" name="gdpr" type="checkbox" class="form-check-input" (ngModelChange)="gdprRettet($event)" ngModel/>
      <label for="gdpr" class="form-check-label">
        Jeg er informeret om, at Uddannelses- og Forskningsstyrelsen behandler personlige oplysninger om mig til
        brug for min ansøgning om godkendelse af befordringsrabat (Ungdomskort).
      </label>
    </div>
  </div>
</div>

<app-gdpr-laes-mere></app-gdpr-laes-mere>

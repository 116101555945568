import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import { UfmSessionLoginService, UfmSessionLogPaaStatus } from '@ufmit/ufm-session';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

@Component({
  selector: 'app-side-bund',
  templateUrl: './side-bund.component.html',
  styleUrls: ['./side-bund.component.scss']
})
export class SideBundComponent implements OnInit {

  trin2Url: string;
  private erLoggetPaa: boolean;

  constructor(
    private loginService: UfmSessionLoginService,
    private userTracking: UfmUserTrackingService
  ) {}

  sendUngdomskortUrlTilGA(): boolean {
    if (this.erLoggetPaa) {
      this.userTracking.sendUrl('/menu/ungdomskort/usikkert');
    } else {
      this.userTracking.sendUrl('/menu/ungdomskort/sikkert');
    }
    return true;
  }

  sendTrin2UrlTilGA(): boolean {
    if (this.erLoggetPaa) {
      this.userTracking.sendUrl('/menu/trin2/usikkert');
    } else {
      this.userTracking.sendUrl('/menu/trin2/sikkert');
    }
    return true;
  }

  ngOnInit() {
    this.trin2Url = environment.trin2Url;
    this.loginService.erLoggetPaa().subscribe((erLoggetPaa) => (this.erLoggetPaa = erLoggetPaa));
  }

}

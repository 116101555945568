import { Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import moment from 'moment/moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Observable, from, of, Subscription, timer } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { mergeMap, concatMap, tap, last, take, catchError, first } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { Moment } from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { HttpClient } from '@angular/common/http';

import { Adresse } from '../../../fælles-utils/adresse/adresse';
import { AdresseForm } from './adresse-form';
import { ApiFormService } from '../api-form.service';
import { DawaAdressePipe } from '../dawa/dawa-adresse-pipe/dawa-adresse.pipe';
import { DawaAdresseService } from '../dawa/dawa-adresse.service';
import { DawaAdresseVaskResultat } from '../dawa/dawa-adresse-vask-resultat';
import { DawaAdgangsAdresseVaskResultat } from '../dawa/dawa-adgangs-adresse-vask-resultat';
import { DawaAktuelAdresse } from '../dawa/dawa-aktuel-adresse';
import { DawaAutoCompleteResultat } from '../dawa/dawa-auto-complete-resultat';
import { environment } from '../../../../environments/environment';
import { FilerService } from '../../filer/filer.service';
import { Kommune } from '../kommune';
import { ProfilService } from '../../../forretnings-sider/profil/profil.service';
import { UfmBackendtid2Service } from '@ufmit/ufm-backendtid2';
import { UfmUserTrackingService } from '@ufmit/ufm-user-tracking';

const ValiderPeriode: ValidatorFn = (fg: UntypedFormGroup) => {
  const fra = fg.controls ? fg.controls.fra.value : null;
  const til = fg.controls ? fg.controls.til.value : null;
  if (!fra || !til || fra <= til) {
    return null;
  } else {
    return { ugyldigPeriode: true };
  }
};

function validerMindstEnFil(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const filer: UntypedFormArray = control as UntypedFormArray;
    for (let i = 0; i < filer.length; i++) {
      const fil: UntypedFormGroup = filer.at(i) as UntypedFormGroup;
      if (fil.controls.data.value) {
        return null;
      }
    }
    return { filMangler: true };
  };
}

@Component({
  selector: 'app-adresse-form',
  templateUrl: './adresse-form.component.html',
  styleUrls: ['./adresse-form.component.scss']
})
export class AdresseFormComponent implements OnInit {

  @Input() adresse: AdresseForm;
  @Input() kommuner: Kommune[];
  @Input() submitKnapTekst: string;
  @Input() skjulLandekodeDK: boolean;
  @Input() sender: boolean;
  @Output() annullerIndtastning$ = new EventEmitter();
  @Output() gemIndtastning$ = new EventEmitter<AdresseForm>();

  form: UntypedFormGroup;
  flyttedatoMin: Date;
  flyttedatoMax: Date;
  ariaLabelLandekode: string;
  gdpr: boolean;
  adressetype: string;
  adresselabel: string;
  visesDatovaelger = false;

  // DAWA ngx-typeahead
  brugerDawa: boolean;
  visManuelIndtastningLink: boolean;
  adresseLinje: string;
  adresseLinje$: Observable<any>;
  adresseForslag: {
    tekst: string;
    dawaAktuelAdresse: DawaAktuelAdresse;
    kommunekode?: string;
    kommunenavn?: string;
  }[] = [];
  visAntalAdresseForslag = 7;
  valgtAdresseForslag: string;
  dialogRef: BsModalRef;
  ukendtAdresse: string;
  dawaAdresse: string;
  @ViewChild('adressevaskResultatB', { static: true }) adressevaskResultatB: TemplateRef<any>;
  @ViewChild('adressevaskResultatC', { static: true }) adressevaskResultatC: TemplateRef<any>;

  kommuneValgtAfBruger: string;
  dawakommunekode: string;
  dawakommunenavn: string;
  @ViewChild('kommuneforslag', { static: true }) kommuneTemplateRef: TemplateRef<any>;

  timerForDawaAdvarsel: Subscription;
  rejsetidFraBopaelTilLokation = '';
  rejsetidResult = -3;

  minStartDato: Date;
  maxStartDato: Date;
  minSlutDato: Date;
  maxSlutDato: Date;
  nu: Moment;
  maxStartDatoFlytning: Date;
  startDatepickerConfig: Partial<BsDatepickerConfig>;
  slutDatepickerConfig: Partial<BsDatepickerConfig>;
  udenlandskAdresse: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private localeService: BsLocaleService,
    private adresseService: DawaAdresseService,
    private dawaAdressePipe: DawaAdressePipe,
    private modalService: BsModalService,
    private profilService: ProfilService,
    private userTracking: UfmUserTrackingService,
    private http: HttpClient,
    private apiFormService: ApiFormService,
    private toastrService: ToastrService,
    private backendtidService: UfmBackendtid2Service,
    private filService: FilerService,
    private ufmUserTrackingService: UfmUserTrackingService) {
    localeService.use('da');

    // adresseLinje typeahead
    this.adresseLinje$ = new Observable((observer: any) => {
      observer.next(this.form.get('adresseLinje').value);
    }).pipe(
      mergeMap((adresseLinje: string) => this.adresseService.autocompleteAdresse(adresseLinje)),
      tap(() => this.lukTimerForDawaAdvarsel()),
      // ignorer dawa typeahead fejl
      catchError((error) => {
        this.opsaetTimerForDawaAdvarsel();
        return of([]);
      })
    );
  }

  ngOnInit() {
    this.backendtidService.backendtid$.subscribe((backendTid) => {
      if (!backendTid) {
        return;
      }
      this.nu = backendTid.clone();
      this.konfigurerDatoVaelger();
    });

    if (this.skjulLandekodeDK) {
      this.ariaLabelLandekode = 'Vælg land. Sverige eller Tyskland';
    } else {
      this.ariaLabelLandekode = 'Vælg land. Danmark, Sverige eller Tyskland';
    }
    this.brugerDawa = this.adresse.landekode === 'DK';
    this.saetFormValideringer(this.adresse.landekode);
    // tjek om rejsetid kan beregnes. Pt. kun muligt for danske rejestrækninger.
    this.profilService.profil$.subscribe((profil) => {
      this.udenlandskAdresse = profil.udenlandskAdresse;
      if (this.udenlandskAdresse) {
        this.rejsetidFraBopaelTilLokation = '';
        this.rejsetidResult = -2;
      }
    });
  }

  saetFormValideringer(landekode: string): void {
    const form = {
      landekode: [this.adresse.landekode, Validators.required],
      adresseLinje: [this.adresseLinje, this.brugerDawa ? Validators.required : Validators.nullValidator],
      vejnavn: [this.adresse.vejnavn, this.brugerDawa ? Validators.nullValidator : Validators.required],
      husnr: [this.adresse.husnr, this.brugerDawa ? Validators.nullValidator : Validators.required],
      etage: [this.adresse.etage, Validators.nullValidator],
      sidedoer: [this.adresse.sidedoer, Validators.nullValidator],
      postnr: this.brugerDawa ?
        [this.adresse.postnr, Validators.nullValidator] :
        [this.adresse.postnr, Validators.compose([
          Validators.required,
          Validators.minLength(landekode === 'DK' ? 4 : 5),
          Validators.maxLength(landekode === 'DK' ? 4 : 5)])
        ],
      bynavn: [this.adresse.bynavn, this.brugerDawa ? Validators.nullValidator : Validators.required]
    };
    let formValideringer = null;
    if (this.adresse.flytning) {
      this.adressetype = 'flytte';
      this.adresselabel = 'Adresse';
      /*this.flyttedatoMin = moment().toDate();
      this.flyttedatoMax = moment().add(21, 'days').toDate();
      if (this.adresse.flytning.flyttedato) {
        if ((this.adresse.flytning.flyttedato < this.flyttedatoMin) || (this.adresse.flytning.flyttedato > this.flyttedatoMax)) {
         this.adresse.flytning.flyttedato = moment.utc().toDate();
        }
      }*/
      form['conavn'] = [this.adresse.flytning.conavn];
      form['flyttedato'] = [this.adresse.flytning.flyttedato, Validators.required];
      form['skolehjem'] = [this.adresse.flytning.skolehjem];
      form['adressebeskyttelse'] = [this.adresse.flytning.adressebeskyttelse];
      if (landekode === 'DK' && !this.brugerDawa) {
        form['kommunekode'] = [this.adresse.flytning.kommunekode.toString(), Validators.required];
      } else {
        form['kommunekode'] = [this.adresse.flytning.kommunekode.toString(), Validators.nullValidator];
      }
    }
    if (this.adresse.praktik) {
      this.adressetype = 'praktik';
      this.adresselabel = 'Praktikstedets adresse';
      form['praktikstedNavn'] = [this.adresse.praktik.stedNavn, Validators.required];
      form['fra'] = [this.adresse.praktik.fra, Validators.required];
      form['til'] = [this.adresse.praktik.til, Validators.required];
      form['rejsetid'] = [this.adresse.praktik.rejsetid, Validators.required];
      form['troOgLoveRejsetid'] = [this.adresse.praktik.troOgLoveRejsetid, Validators.required];
      formValideringer = { validator: ValiderPeriode };
    }
    if (this.adresse.udlandUddSted) {
      this.adressetype = 'udenlandske';
      this.adresselabel = 'Uddannelsesstedets adresse';
      form['uddstedNavn'] = [this.adresse.udlandUddSted.stedNavn, Validators.required];
      form['fra'] = [this.adresse.udlandUddSted.fra, Validators.required];
      form['rejsetid'] = [this.adresse.udlandUddSted.rejsetid, Validators.required];
      form['troOgLoveRejsetid'] = [this.adresse.udlandUddSted.troOgLoveRejsetid, Validators.required];
    }
    if (this.adresse.ekstraUddsted) {
      this.adressetype = 'uddannelsessted';
      this.adresselabel = 'Uddannelsesstedets adresse';
      form['uddstedNavn'] = [this.adresse.ekstraUddsted.stedNavn, Validators.required];
      form['fra'] = [this.adresse.ekstraUddsted.fra, Validators.required];
      form['til'] = [this.adresse.ekstraUddsted.til, Validators.required];
      formValideringer = { validator: ValiderPeriode };
      form['rejsetid'] = [this.adresse.ekstraUddsted.rejsetid, Validators.required];
      form['troOgLoveRejsetid'] = [this.adresse.ekstraUddsted.troOgLoveRejsetid, Validators.required];
    }
    this.form = this.fb.group(form, formValideringer);
    // tilføj formarrays der hvor fil upload er relevant
    if (this.adresse.praktik) {
      this.form.addControl('filer', this.fb.array([this.filService.nyFil('praktik_fil')]));
    }
    if (this.adresse.udlandUddSted) {
      this.form.addControl('filer', this.fb.array([this.filService.nyFil('udenlandsk_uddsted_fil')], { validators: [validerMindstEnFil()] }));
    }
    if (this.adresse.ekstraUddsted) {
      this.form.addControl('filer', this.fb.array([this.filService.nyFil('uddsted_fil')]));
    }
    this.form.controls.landekode.valueChanges
      .pipe(first())
      .subscribe((nyLandekode) => {
        this.brugerDawa = nyLandekode === 'DK';
        this.adresse.landekode = nyLandekode;
        this.saetFormValideringer(nyLandekode);
      });
  }

  private erDanmark(landekode: string): boolean {
    return landekode === 'DK';
  }

  private laesFormVaerdier(): void {
    this.adresse.landekode = this.form.get('landekode').value;
    this.adresseLinje = this.form.get('adresseLinje').value;
    this.adresse.vejnavn = this.form.get('vejnavn').value;
    this.adresse.husnr = this.form.get('husnr').value;
    this.adresse.etage = this.form.get('etage').value || '';
    this.adresse.sidedoer = this.form.get('sidedoer').value || '';
    this.adresse.postnr = this.form.get('postnr').value;
    this.adresse.bynavn = this.form.get('bynavn').value;
    if (this.adresse.flytning) {
      this.adresse.flytning.conavn = this.form.get('conavn').value || '';
      this.adresse.flytning.flyttedato = this.laesInputFeltSomUTCDato('flyttedato');
      this.adresse.flytning.skolehjem = this.form.get('skolehjem').value;
      this.adresse.flytning.adressebeskyttelse = this.form.get('adressebeskyttelse').value;
      this.adresse.flytning.kommunekode = parseInt(this.form.get('kommunekode').value, 10);
    } else if (this.adresse.praktik) {
      this.adresse.praktik.stedNavn = this.form.get('praktikstedNavn').value;
      this.adresse.praktik.fra = this.laesInputFeltSomUTCDato('fra');
      this.adresse.praktik.til = this.laesInputFeltSomUTCDato('til');
      this.adresse.praktik.rejsetid = this.form.get('rejsetid').value;
      this.adresse.praktik.troOgLoveRejsetid = this.form.get('troOgLoveRejsetid').value;
    } else if (this.adresse.udlandUddSted) {
      this.adresse.udlandUddSted.stedNavn = this.form.get('uddstedNavn').value;
      this.adresse.udlandUddSted.fra = this.laesInputFeltSomUTCDato('fra');
      this.adresse.udlandUddSted.rejsetid = this.form.get('rejsetid').value;
      this.adresse.udlandUddSted.troOgLoveRejsetid = this.form.get('troOgLoveRejsetid').value;
    } else {
      this.adresse.ekstraUddsted.stedNavn = this.form.get('uddstedNavn').value;
      this.adresse.ekstraUddsted.fra = this.laesInputFeltSomUTCDato('fra');
      this.adresse.ekstraUddsted.til = this.laesInputFeltSomUTCDato('til');
      this.adresse.ekstraUddsted.rejsetid = this.form.get('rejsetid').value;
      this.adresse.ekstraUddsted.troOgLoveRejsetid = this.form.get('troOgLoveRejsetid').value;
    }
    this.adresse.filer = [];
    if (this.form.get('filer')) {
      // kopier filer til adresseform før post mod API
      const filer: UntypedFormArray = this.form.get('filer') as UntypedFormArray;
      for (let i = 0 ; i < filer.length ; i++) {
        const fil: UntypedFormGroup = filer.at(i) as UntypedFormGroup;
        this.adresse.filer.push({
          filnavn: fil.controls.filnavn.value,
          data: fil.controls.data.value
        });
      }
    }
  }

  private laesInputFeltSomUTCDato(feltnavn: string): Date {
    const value = this.form.get(feltnavn).value;
    return this.konverterTilUTCDato(value);
  }

  // input er Date hvis input kommer fra API eller datepicker.
  // input er string hvis input kommer fra input felter uden datepicker eller fra unit tests
  private konverterTilUTCDato(input: string | Date) {
    if (!input) {
      return null;
    }
    let dato: Date;
    if (typeof input === 'string') {
      dato = moment.utc(input, 'DD-MM.YYYY').toDate();
    } else {
      dato = input;
    }
    return moment.utc([dato.getFullYear(), dato.getMonth(), dato.getDate()]).toDate();
  }

  annullerIndtastning(): void {
    this.annullerIndtastning$.emit();
  }

  private opsaetTimerForDawaAdvarsel(): void {
    if (this.timerForDawaAdvarsel) {
      return;
    }
    this.timerForDawaAdvarsel = timer(30000).subscribe(() => {
      // todo: vi bør tjekke browser offline/online status og give en bedre besked hvis browser er offline
      //       https://caniuse.com/#feat=online-status
      this.toastrService.error(
        'Adresseforslag fra DAWA (Danmarks Adressers Web API) virker ikke i øjeblikket. ' +
        'Du bør evt. skifte til manuel indtastning af dansk adresse'
      );
    });
  }

  private lukTimerForDawaAdvarsel(): void {
    if (!this.timerForDawaAdvarsel) {
      return;
    }
    this.timerForDawaAdvarsel.unsubscribe();
    this.timerForDawaAdvarsel = null;
  }

  private dawaSvarerIkkeSkifterTilManuelIndtastning(): void {
    this.toastrService.error(
      'Adresseforslag fra DAWA (Danmarks Adressers Web API) virker ikke i øjeblikket. ' +
      'Skifter til manuel indtastning af dansk adresse'
    );
    this.brugManuelIndtastning();
    this.lukTimerForDawaAdvarsel();
    if (this.adresse.flytning) {
      this.userTracking.sendUrl('/flytning/dawa-svarer-ikke/skifter-til-manuel-indtastning');
    } else if (this.adresse.praktik) {
      this.userTracking.sendUrl('/praktik/dawa-svarer-ikke/skifter-til-manuel-indtastning');
    } else if (this.adresse.ekstraUddsted) {
      this.userTracking.sendUrl('/ekstra/dawa-svarer-ikke/skifter-til-manuel-indtastning');
    }
  }

  private dawaSvarerIkkeGemmerManuelIndtasning(): void {
    this.toastrService.error(
      'Adresseforslag fra DAWA (Danmarks Adressers Web API) virker ikke i øjeblikket. Gemmer ikke tjekket adresse'
      );
    if (this.adresse.flytning) {
      this.userTracking.sendUrl('/flytning/dawa-svarer-ikke/gemmer-manuel-indtastning');
    } else if (this.adresse.praktik) {
      this.userTracking.sendUrl('/praktik/dawa-svarer-ikke/gemmer-manuel-indtastning');
    } else if (this.adresse.ekstraUddsted) {
      this.userTracking.sendUrl('/ekstra/dawa-svarer-ikke/gemmer-manuel-indtastning');
    }
    this.afslutOgEmitAdresse6();
  }

  beregnRejsetid(): void {
    this.laesFormVaerdier();
    if (this.erDanmark(this.form.get('landekode').value)) {
      const destinationAdresse: Adresse = this.apiFormService.getAdresseJson(this.adresse);
      this.getRejsetid(destinationAdresse).subscribe(
        (result) => {
          if (result > 0 ) {
            // tslint:disable-next-line: no-shadowed-variable
            const timer: number = Math.floor(result / 60);
            const minutter: number = result % 60;
            let timerText = '';
            let minutterText = '';
            let konjunktionText = '';
            if (timer > 0 && minutter > 0) {
              konjunktionText = ' og ';
            }
            if (timer === 1) {
              timerText = timer + ' time';
            } else if (timer > 1) {
              timerText = timer + ' timer';
            }
            if (minutter === 1) {
              minutterText = minutter + ' minut';
            } else if (minutter > 1) {
              minutterText = minutter + ' minutter';
            }
            this.rejsetidFraBopaelTilLokation = 'Vi har registreret, ved opslag i rejseplanen i myldretiden på din strækning, at rejsetiden er '
              + timerText + konjunktionText + minutterText + '.';
          } else {
            this.rejsetidFraBopaelTilLokation = '';
          }
          this.rejsetidResult = result;
          return result;
        });
    } else {
      this.rejsetidFraBopaelTilLokation = '';
      this.rejsetidResult = -2;
    }
  }

  getRejsetid(adresse: Adresse): Observable<any> {
    return this.http
      .post<number>(environment.ukApi + '/rejsestraekninger/rejsetid', adresse, { headers: { NoInterceptorTimeout: 'x' } })
      .pipe(map((rejsetid) => {
        return rejsetid; 
      })
    );
  }

  // Trin 1 ud af 6: Gem indtastning

  gemIndtastning(): void {
    this.laesFormVaerdier();
    if (!this.erDanmark(this.form.get('landekode').value)) {
      this.afslutOgEmitAdresse6();
    } else if (this.brugerDawa) {
      this.adresseVask2(this.form.get('adresseLinje').value);
    } else {
      this.adresseVask2(this.adresse);
    }
  }

  // trin 2 ud af 6: DAWA adressevask

  // DAWA adresse vask før adresse sendes til server. Der vises en modal dialog hvis oplysningerne ikke er korrekte
  private adresseVask2(adresse: AdresseForm | string): void {
    let dawaSvarerIkke: boolean;
    // adressevask:
    // - der anvendes fuld adresse hvis bruger har indtastet etage eller dør i manuel indtastning.
    // - der anvendes fuld adresse hvis bruger melder flytning
    // - dvs. for uddannelsessted adresser anvendes der i de fleste tilfælde adgangsadressevask
    let brugAdgangsadresseVask: boolean;
    if (adresse instanceof AdresseForm) {
      brugAdgangsadresseVask = !this.adresse.etage && !this.adresse.sidedoer;
    } else {
      brugAdgangsadresseVask = !this.adresse.flytning;
    }
    this.adresseService
      .adresseVaskEllerAdgangsadresseVask(adresse, brugAdgangsadresseVask)
      .pipe(
        catchError((error) => {
          dawaSvarerIkke = true;
          return of(null);
        })
      )
      .subscribe((adresseVask) => {
          if (dawaSvarerIkke) {
            // DAWA svarer ikke. Fortsæt gem hvis manuel indtastning anvendes. Stop gem hvis typeahead indtastning anvendes
            if (adresse instanceof AdresseForm) {
              this.dawaSvarerIkkeGemmerManuelIndtasning();
            } else {
              this.dawaSvarerIkkeSkifterTilManuelIndtastning();
            }
            return;
          }
          if (!adresseVask.resultater.length) {
            this.toastrService.error('Fandt ingen adresseforslag for den indtastede adresse');
            return;
          }

          if (adresseVask.kategori !== 'A') {
            this.initiliserAdresseforslag3(adresse, adresseVask);
          } else {
            this.form.get('vejnavn').setValue(adresseVask.resultater[0].adresse.vejnavn);
            this.form.get('husnr').setValue(adresseVask.resultater[0].adresse.husnr);
            if (adresseVask instanceof DawaAdresseVaskResultat) {
              this.form.get('etage').setValue(adresseVask.resultater[0].adresse.etage);
              this.form.get('sidedoer').setValue(adresseVask.resultater[0].adresse.dør);  
            }
            this.form.get('postnr').setValue(adresseVask.resultater[0].adresse.postnr);
            this.form.get('bynavn').setValue(adresseVask.resultater[0].adresse.postnrnavn);
            this.laesFormVaerdier();
            if (!this.adresse.flytning) {
              this.afslutOgEmitAdresse6();
            } else if (!this.brugerDawa) {
              this.kommuneVask4();
            } else {
              this.adresseService
                .hentKommunekode(this.adresse)
                .subscribe((kommunekodeFraDawa) => {
                  this.form.get('kommunekode').setValue(kommunekodeFraDawa);
                  this.adresse.flytning.kommunekode = parseInt(kommunekodeFraDawa, 10);
                  this.afslutOgEmitAdresse6();
                });
            }
          }
      });
  }

  // Trin 3 ud af 6: Vis alert med DAWA adressevask foreslag

  // DAWA adressevask kategori B eller C. Initialiser popup med adresseforslag
  private initiliserAdresseforslag3(
    adresse: AdresseForm | string,
    dawaAdresseVask: DawaAdresseVaskResultat | DawaAdgangsAdresseVaskResultat): void {
    this.ukendtAdresse = this.adresseService.betegnelse(adresse);
    let aktueladresse: any;
    this.adresseForslag.length = 0;
    for (let i = 0; i < dawaAdresseVask.resultater.length; i++) {
      aktueladresse = dawaAdresseVask.resultater[i].aktueladresse;
      this.adresseForslag.push({
        tekst: this.dawaAdressePipe.transform(aktueladresse),
        dawaAktuelAdresse: aktueladresse
      });
    }
    if (this.adresse.flytning) {
      this.hentKommunerTilAdresseForslag3(7);
    } else {
      this.visAdresseForslagAlert3();
    }
  }

  // flytning: for hver række i adresseForslag hentes kommunekode og kommunenavn
  private hentKommunerTilAdresseForslag3(antal: number): void {
    let dawaSvarerIkke: boolean;
    from(this.adresseForslag)
      .pipe(
        take(antal),
        concatMap(
          (adresseForslag) => {
            if (adresseForslag.kommunenavn) {
              return of(adresseForslag.kommunekode);
            } else {
              return this.adresseService
                .hentKommunekode(adresseForslag.tekst)
                .pipe(
                  tap((kommunekode) => {
                    adresseForslag.kommunekode = kommunekode;
                    const kommune: Kommune[] = this.kommuner.filter((row) => row.kode.toString() === kommunekode);
                    adresseForslag.kommunenavn = kommune.length ? kommune[0].navn : kommunekode;
                  }),
                  catchError((error) => {
                    dawaSvarerIkke = true;
                    return of(null);
                  })
                );
            }
          }
        ),
        last()
      ).subscribe(() => {
        if (dawaSvarerIkke) {
          if (this.dialogRef) {
            this.dialogRef.hide();
            this.dialogRef = null;
          }
          this.dawaSvarerIkkeSkifterTilManuelIndtastning();
          return;
        }
        if (!this.dialogRef) {
          this.visAdresseForslagAlert3();
        }
      });
  }

  private visAdresseForslagAlert3(): void {
    if (this.adresseForslag.length === 1) {
      // DAWA adressevask kategori B. Et resultat. Adresse næsten korrekt. Vis forslag til rettelse
      this.dawaAdresse = this.adresseForslag[0].tekst;
      this.dialogRef = this.modalService.show(this.adressevaskResultatB);
    } else {
      // DAWA adressevask kategori C. Adresse er flertydig. Vis liste med forslag
      this.visAntalAdresseForslag = 7;
      this.valgtAdresseForslag = null;
      this.dialogRef = this.modalService.show(this.adressevaskResultatC);
    }
  }

  visFlereAdresseForslag3(): void {
    if (this.adresse.flytning) {
      this.hentKommunerTilAdresseForslag3(this.visAntalAdresseForslag + 7);
    }
    this.visAntalAdresseForslag = this.visAntalAdresseForslag + 7;
  }

  disableAdresseforslagFortsaet3(): boolean {
    if (!this.brugerDawa) {
      return false;
    } else if (this.valgtAdresseForslag === null) {
      return true;
    } else {
      return false;
    }
  }

  adresseforslagFortryd3(): void {
    this.dialogRef.hide();
    this.dialogRef = null;
    this.visManuelIndtastningLink = true;
  }

  adresseforslagFortsaet3(): void {
    this.dialogRef.hide();
    this.dialogRef = null;
    if (this.valgtAdresseForslag !== null) {
      // brugeren har valgt en DAWA adresse
      const adresse = this.adresseForslag[this.valgtAdresseForslag];
      this.form.get('vejnavn').setValue(adresse.dawaAktuelAdresse.vejnavn);
      this.form.get('husnr').setValue(adresse.dawaAktuelAdresse.husnr);
      this.form.get('etage').setValue(adresse.dawaAktuelAdresse.etage);
      this.form.get('sidedoer').setValue(adresse.dawaAktuelAdresse.dør);
      this.form.get('postnr').setValue(adresse.dawaAktuelAdresse.postnr);
      this.form.get('bynavn').setValue(adresse.dawaAktuelAdresse.postnrnavn);
      if (this.adresse.flytning) {
        this.form.get('kommunekode').setValue(adresse.kommunekode);
      }
      this.laesFormVaerdier();
    } else {
      if (this.adresse.flytning) {
        this.userTracking.sendUrl('/flytning/dawa-adresseforslag-bruges-ikke');
      } else if (this.adresse.praktik) {
        this.userTracking.sendUrl('/praktik/dawa-adresseforslag-bruges-ikke');
      } else if (this.adresse.ekstraUddsted) {
        this.userTracking.sendUrl('/ekstra/dawa-adresseforslag-bruges-ikke');
      }
    }
    this.afslutOgEmitAdresse6();
  }

  adresseAdvarselBrugAdresseFraDawa3(): void {
    this.valgtAdresseForslag = '0';
    this.adresseforslagFortsaet3();
  }

  adresseAdvarselBrugUkendtAdresse3(): void {
    this.valgtAdresseForslag = null;
    this.adresseforslagFortsaet3();
  }

  // Trin 4 ud af 6: DAWA kommune tjek

  private kommuneVask4(): void {
    let dawaSvarerIkke: boolean;
    if (!this.adresse.flytning) {
      this.afslutOgEmitAdresse6();
      return;
    }
    // meld flytning uden DAWA adresse vask. tjek valgt kommune!
    this.adresseService
      .hentKommunekode(this.adresse)
      .pipe(
        catchError((error) => {
          dawaSvarerIkke = true;
          return of('');
        })
      )
      .subscribe((kommunekodeFraDawa) => {
        if (dawaSvarerIkke) {
          this.dawaSvarerIkkeGemmerManuelIndtasning();
          return;
        }
        if (kommunekodeFraDawa === this.adresse.flytning.kommunekode.toString()) {
          this.afslutOgEmitAdresse6();
        } else {
          const kommuneBrugerValgt = this.kommuner.filter((x) => x.kode === this.adresse.flytning.kommunekode);
          if (kommuneBrugerValgt.length) {
            this.kommuneValgtAfBruger = kommuneBrugerValgt[0].navn;
          } else {
            this.kommuneValgtAfBruger = 'Ukendt kommunekode ' + this.adresse.flytning.kommunekode.toString();
          }
          const kommuneDawaValgt = this.kommuner.filter((x) => x.kode.toString() === kommunekodeFraDawa);
          this.dawakommunekode = kommunekodeFraDawa;
          if (kommuneDawaValgt.length) {
            this.dawakommunenavn = kommuneDawaValgt[0].navn;
          } else {
            this.dawakommunenavn = 'Ukendt kommunekode ' + kommunekodeFraDawa;
          }
          this.visKommuneForslag5();
        }
      });
  }

  // Trin 5 ud af 6: Vis alert med DAWA kommuneforeslag

  private visKommuneForslag5(): void {
    this.valgtAdresseForslag = null;
    this.dialogRef = this.modalService.show(this.kommuneTemplateRef);
  }

  kommuneforslagFortryd5(): void {
    this.dialogRef.hide();
    this.dialogRef = null;
  }
  kommuneforslagBrugKommuneFraDawa5(): void {
    this.dialogRef.hide();
    this.dialogRef = null;
    this.form.get('kommunekode').setValue(this.dawakommunekode);
    this.adresse.flytning.kommunekode = parseInt(this.dawakommunekode, 10);
    this.afslutOgEmitAdresse6();
  }
  kommuneforslagBrugKommuneValgtAfBruger5(): void {
    this.dialogRef.hide();
    this.dialogRef = null;
    this.userTracking.sendUrl('/flytning/dawa-kommune-bruges-ikke');
    this.afslutOgEmitAdresse6();
  }

  // Trin 6 ud af 6: done. emit den indtastede adresse til parent component

  private afslutOgEmitAdresse6(): void {
    this.gemIndtastning$.emit(this.adresse);
  }

  brugManuelIndtastning(): void {
    this.brugerDawa = false;
    this.saetFormValideringer(this.form.get('landekode').value);
    this.visManuelIndtastningLink = false;
    if (this.adresse.flytning) {
      this.userTracking.sendUrl('/flytning/uden-dawa');
    } else if (this.adresse.praktik) {
      this.userTracking.sendUrl('/praktik/uden-dawa');
    } else if (this.adresse.ekstraUddsted) {
      this.userTracking.sendUrl('/ekstra/dawa');
    }
  }

  dawaAdresseValgt(e: TypeaheadMatch): void {
    this.adresseLinje = e.value;
    const item: DawaAutoCompleteResultat = e.item;
    if (item.type === 'vejnavn') {
      return;
    }
    this.form.get('vejnavn').setValue(item.data.vejnavn);
    this.form.get('husnr').setValue(item.data.husnr);
    if (item.type === 'adresse') {
      this.form.get('etage').setValue(item.data.etage);
      this.form.get('sidedoer').setValue(item.data.dør);
    }
    this.form.get('postnr').setValue(item.data.postnr);
    this.form.get('bynavn').setValue(item.data.postnrnavn);
    if (!this.adresse.flytning) {
      return;
    }
    this.adresseService
      .hentKommunekode(this.adresseLinje)
      .subscribe((kommunekode) => {
        this.form.get('kommunekode').setValue(kommunekode);
      });
  }

  datoBlevValgt(): void {
    this.konfigurerDatoVaelger();
  }

  private konfigurerDatoVaelger(): void {
    if (!this.nu) {
      // Initielt vil konfigurerDatoVaelger kunne blive kaldt før backendtid er modtaget.
      // I så fald giver det ikke mening, at fortsætte.
      // Så snart backendTid modtages kaldes konfigurerDatoVaelger og alt er godt igen.
      return;
    }
    const dagsdato = this.nu.clone().startOf('day');
    let startDato: Moment;
    let slutDato: Moment;
    if (this.adresse.flytning) {
      startDato = this.konverterTilMoment(this.adresse.flytning.flyttedato);
    } else if (this.adresse.udlandUddSted) {
      startDato = this.konverterTilMoment(this.adresse.udlandUddSted.fra);
    } else if (this.adresse.praktik) {
      startDato = this.konverterTilMoment(this.adresse.praktik.fra);
      slutDato = this.konverterTilMoment(this.adresse.praktik.til);
    } else if (this.adresse.ekstraUddsted) {
      startDato = this.konverterTilMoment(this.adresse.ekstraUddsted.fra);
      slutDato = this.konverterTilMoment(this.adresse.ekstraUddsted.til);
    }

    // minSlutDato: >= dagsdato. periode max 1 år minus 1 dag
    const minStartDato1 = dagsdato;
    let minStartDato2: Moment;
    if (slutDato) {
      minStartDato2 = slutDato.clone().subtract(1, 'year').add(1, 'day');
    }
    if (!minStartDato2 || minStartDato2.isBefore(minStartDato1)) {
      this.minStartDato = minStartDato1.toDate();
    } else {
      this.minStartDato = minStartDato2.toDate();
    }
    if (this.adresse.flytning) {
      this.maxStartDatoFlytning = this.konverterTilMoment(this.minStartDato).clone().add(3, 'week').toDate();
    }

    // const maxStartDato1: Moment = dagsdato.clone().add(1, 'month');
    const maxStartDato1: Moment = dagsdato.clone().add(365, 'day');
    let maxStartDato2: Moment;
    if (slutDato) {
      maxStartDato2 = slutDato;
    }
    if (!maxStartDato2 || maxStartDato1.isBefore(maxStartDato2)) {
      this.maxStartDato = maxStartDato1.toDate();
    } else {
      this.maxStartDato = maxStartDato2.toDate();
    }

    if (startDato) {
      this.maxSlutDato = startDato.clone().add(1, 'year').subtract(1, 'day').toDate();
    } else {
      this.maxSlutDato = dagsdato.clone().add(1, 'year').add(1, 'month').subtract(1, 'day').toDate();
    }
    let minSlutDato2: Moment;
    if (startDato) {
      minSlutDato2 = startDato;
    }
    if (!minSlutDato2) {
      this.minSlutDato = dagsdato.toDate();
    } else {
      this.minSlutDato = minSlutDato2.toDate();
    }
  }

  private konverterTilMoment(input: string | Date) {
    if (!input) {
      return null;
    }
    let dato: Moment;
    if (typeof input === 'string') {
      if (input.match(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/)) {
        dato = moment(input, 'DD.MM.YYYY');
      } else {
        console.error('ukendt dato format', input);
        return null;
      }
    } else {
      dato = moment(input);
    }
    // Benytter backendTid, så korrekt tidszone beholdes
    const backendTid = this.nu.clone();
    backendTid.year(dato.year());
    backendTid.month(dato.month());
    backendTid.date(dato.date());
    return backendTid.startOf('day');
  }

  filUploaded(filUploaded: boolean) {
    if (filUploaded) {
      this.ufmUserTrackingService.sendUrl('/adresse-form/' + this.adressetype + '/fil/uploaded');
    } else {
      this.ufmUserTrackingService.sendUrl('/adresse-form/' + this.adressetype + '/fil/slettet');
    }
    this.form.controls.filer.updateValueAndValidity();
  }

}

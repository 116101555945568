import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'ANNULERET': {
        return 'Slettet';
      }
      case 'GODKENDT': {
        return '';
      }
      case 'AFVENTER_UDDANNELSESSTEDET': {
        return 'Til sagsbehandling på uddannelsessted';
      }
      default: {
        return value;
      }
    }
  }
}

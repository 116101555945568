import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

import { FilerService } from './filer.service';

@Component({
  selector: 'app-filer',
  templateUrl: './filer.component.html',
  styleUrls: ['./filer.component.scss']
})
export class FilerComponent implements OnChanges {

  @Input() form: UntypedFormArray;
  @Input() label: string;
  @Input() idPrefix: string;
  @Input() accept: string;
  @Input() ariaLabelFil: string;
  @Input() ariaLabelTilfoejFiler: string;
  @Output() filUploaded$: EventEmitter<boolean> = new EventEmitter();

  faPlusCircle = faPlusCircle;
  labelSafe: SafeHtml;

  constructor(private filerService: FilerService, private sanitizer: DomSanitizer) { }

  ngOnChanges() {
    this.labelSafe = this.sanitizer.bypassSecurityTrustHtml(this.label);
  }

  private tilfoejFil() {
    this.form.push(this.filerService.nyFil(this.idPrefix));
  }

  uploadFlereDokumenter(): boolean {
    this.tilfoejFil();
    return false;
  }

}

// http://dawa.aws.dk/datavask/adresser?betegnelse= response format
import { DawaAktuelAdresse } from './dawa-aktuel-adresse';
export class DawaAdresseVaskResultat {
    kategori: string;
    resultater: {
      adresse: {
        id: string;
        vejnavn: string;
        adresseringsvejnavn: string;
        husnr: string;
        supplerendebynavn: string;
        postnr: string;
        postnrnavn: string;
        status: number;
        virkningstart: string;
        virkningslut: string;
        adgangsadresseid: string;
        etage: string;
        dør: string;
        href: string;
      };
      aktueladresse: DawaAktuelAdresse;
      vaskeresultat: {
        variant: {
          vejnavn: string;
          husnr: string;
          etage: string;
          dør: string;
          supplerendebynavn: string;
          postnr: string;
          postnrnavn: string;
        };
        afstand: number;
        forskelle: {
          vejnavn: number;
          husnr: number;
          postnr: number;
          postnrnavn: number;
          etage: number;
          dør: number;
        };
        parsetadresse: {
          vejnavn: string;
          husnr: string;
          etage: string;
          dør: string;
          postnr: string;
          postnrnavn: string;
        };
        ukendtetokens: any[];
        anvendtstormodtagerpostnummer: string;
      };
    }[];
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { GdprService } from '../gdpr.service';

@Injectable({
  providedIn: 'root'
})
export class IkkeGdprGuard  {

  constructor(private gdprService: GdprService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.gdprService.erGdprGodkendt()) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}

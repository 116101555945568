import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { UfmSessionLoginService } from '@ufmit/ufm-session';

@Component({
  selector: 'app-logaf',
  templateUrl: './log-af.component.html',
  styleUrls: ['./log-af.component.scss']
})
export class LogAfComponent implements OnInit {

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private ufmSessionLoginService: UfmSessionLoginService) { }

  ngOnInit() {
    this.ufmSessionLoginService.erLoggetPaa().subscribe((erLoggetPaa) => {
      if (erLoggetPaa) {
        // vi er IKKE logget af. Retur til forsiden
        this.router.navigate(['/']);
      } else {
        const fortsaet_til_trin_2 = this.cookieService.get('fortsaet_til_trin_2');
        if (fortsaet_til_trin_2) {
          this.cookieService.delete('fortsaet_til_trin_2');
        }
        if (fortsaet_til_trin_2) {
          window.location.href = environment.trin2Url;
        }
      }
    });

  }
}

import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import da from '@angular/common/locales/da';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule, TypeaheadConfig } from 'ngx-bootstrap/typeahead';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { daLocale } from 'ngx-bootstrap/locale';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// delte komponenter
import { UfmBackendtid2Module } from '@ufmit/ufm-backendtid2';
import { UfmFejlhaandteringModule } from '@ufmit/ufm-fejlhaandtering';
import { UfmFuldmagtModule } from '@ufmit/ufm-fuldmagt';
import { UfmPropertiesModule } from '@ufmit/ufm-properties';
import { UfmSessionModule } from '@ufmit/ufm-session';
import { UfmUserTrackingModule } from '@ufmit/ufm-user-tracking';

// services
import { ApiFormService } from './fælles-utils/adresse/api-form.service';
import { ProfilService } from './forretnings-sider/profil/profil.service';
import { RejsestraekningService } from './forretnings-sider/rejsestraekninger/rejsestraekning.service';
// pipes
import { DawaAdressePipe } from './fælles-utils/adresse/dawa/dawa-adresse-pipe/dawa-adresse.pipe';
import { AdressePipe } from './fælles-utils/adresse/adresse-pipe/adresse.pipe';
import { LandenavnPipe } from './fælles-utils/adresse/landenavn-pipe/landenavn.pipe';
import { LangdatoPipe } from './fælles-utils/langdato-pipe/langdato.pipe';
import { PostnrPipe } from './fælles-utils/adresse/postnr-pipe/postnr.pipe';
import { StatusPipe } from './forretnings-sider/rejsestraekninger/status/status.pipe';
import { TypePipe } from './forretnings-sider/rejsestraekninger/type/type.pipe';
// components
import { AppComponent } from './app.component';
import { RejsestraekningComponent } from './forretnings-sider/rejsestraekninger/rejsestraekning/rejsestraekning.component';
import { ProfilComponent } from './forretnings-sider/profil/profil.component';
import { RejsestraekningerComponent } from './forretnings-sider/rejsestraekninger/rejsestraekninger.component';
import { GodkendelseComponent } from './forretnings-sider/godkendelse/godkendelse.component';
import { VisAdresseComponent } from './fælles-utils/adresse/vis-adresse/vis-adresse.component';
import { AdresseFormComponent } from './fælles-utils/adresse/adresse-form/adresse-form.component';
import { PraktikComponent } from './forretnings-sider/praktikplads/praktik.component';
import { UdenlandskUddannelsesstedComponent } from './forretnings-sider/udenlandsk-uddannelsessted/udenlandsk-uddannelsessted.component';
import { EkstraUddannelsesstedComponent } from './forretnings-sider/ekstra-uddannelsessted/ekstra-uddannelsessted.component';
import { GdprCheckboxComponent } from './side-struktur/gdpr/gdpr-checkbox/gdpr-checkbox.component';
import { GdprComponent } from './side-struktur/gdpr/gdpr.component';
import { SideBundComponent } from './side-struktur/side-bund/side-bund.component';
import { MenuComponent } from './side-struktur/menu/menu.component';
import { LogAfComponent } from './side-struktur/log-af/log-af.component';
import { ForsideComponent } from './forretnings-sider/forside/forside.component';
import { GdprLaesMereComponent } from './side-struktur/gdpr/gdpr-laes-mere/gdpr-laes-mere.component';
import { FoldUdComponent } from './fælles-utils/animationer/fold-ud/fold-ud.component';
import { environment } from '../environments/environment';
import { RejsetidComponent } from './side-struktur/rejsetid/rejsetid.component';
import { RejsetidLaesMereComponent } from './side-struktur/rejsetid/rejsetid-laes-mere/rejsetid-laes-mere.component';
import { FilerComponent } from './fælles-utils/filer/filer.component';
import { FilComponent } from './fælles-utils/filer/fil/fil.component';
import { KvitteringFilerComponent } from './fælles-utils/filer/kvittering-filer/kvittering-filer.component';
import { BerigAnsoegningComponent } from './forretnings-sider/berig-ansoegning/berig-ansoegning.component';

registerLocaleData(da);
defineLocale('da', daLocale);

export function getBsDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    // datepicker udskriver "invalid date" når der manuelt indtastes en ugyldig dato.
    // Se open issue https://github.com/valor-software/ngx-bootstrap/issues/3513
    // ikke fikses i version 3.0.1 af ngx-bootstrap
    dateInputFormat: 'DD-MM-YYYY',
    containerClass: 'theme-ungdomskort'
  });
}

function loadEnvironmentJson(http: HttpClient): Promise<boolean | void> {
  const headers: { [key: string]: string } = { NoInterceptorAgerendeCpr: 'x' };
  return new Promise<boolean>((resolve) => {
      http
        .get('assets/environment.json', { headers: headers })
        .toPromise()
        .then((environmentValues) => {
          for (const key in environmentValues) {
            if (environmentValues.hasOwnProperty(key)) {
              environment[key] = environmentValues[key];
            }
          }
          resolve(true);
        })
        .catch(function(reason) {
          console.error('Failed to load environment properties. reason=', reason);
        });
  });
}

function initializeKeycloak(keycloakService: KeycloakService): Promise<boolean | void> {
  const script = document.createElement('script');
  script.src = environment.keycloak.url + '/js/keycloak.js';
  document.body.appendChild(script);
  if (environment.demo) {
    const re: RegExp = new RegExp('\\?requestid=(\\d+)');
    const m: RegExpMatchArray = window.location.href.match(re);
    if (m) {
      const us2000RequestId: string = m[1];
      sessionStorage.setItem('us2000RequestId', us2000RequestId);
    }
  }
  return keycloakService
    .init({
      config: environment.keycloak,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    })
    .catch(function(reason) {
      console.error('Failed to initialize Keycloak', reason);
    });
}

function initializeApplication(httpClient: HttpClient, keycloakService: KeycloakService) {
  return () =>
    loadEnvironmentJson(httpClient).then(() =>
      initializeKeycloak(keycloakService)
    );
}

@NgModule({
  declarations: [
    AppComponent,
    ProfilComponent,
    RejsestraekningComponent,
    RejsestraekningerComponent,
    GodkendelseComponent,
    AdressePipe,
    DawaAdressePipe,
    LangdatoPipe,
    PostnrPipe,
    StatusPipe,
    TypePipe,
    VisAdresseComponent,
    AdresseFormComponent,
    PraktikComponent,
    UdenlandskUddannelsesstedComponent,
    EkstraUddannelsesstedComponent,
    LandenavnPipe,
    GdprCheckboxComponent,
    GdprComponent,
    SideBundComponent,
    MenuComponent,
    LogAfComponent,
    ForsideComponent,
    GdprLaesMereComponent,
    FoldUdComponent,
    RejsetidComponent,
    RejsetidLaesMereComponent,
    FilerComponent,
    FilComponent,
    KvitteringFilerComponent,
    BerigAnsoegningComponent
  ],
  imports: [
    BrowserModule,
    CollapseModule.forRoot(),
    HttpClientModule,
    LoadingBarHttpClientModule,
    AppRoutingModule,
    FormsModule,
    KeycloakAngularModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    TypeaheadModule,
    FontAwesomeModule,
    UfmBackendtid2Module.forRoot({
      environment: { apiPropertyNavn: 'ukApi' },
      tekster: { hentet: 'Hentet:' }
    }),
    UfmFejlhaandteringModule,
    UfmFuldmagtModule.forRoot({
      apiLoginEndpoint: 'loginsb',
      tekster: {
        fuldmagtsValg: { fuldmagtstype: 'Fuldmagt til Transportrabat' }
      }
    }),
    UfmPropertiesModule.forRoot({
      environment: environment
    }),
    UfmSessionModule.forRoot({ logAfUrl: '/logaf', apiLoginEndpoint: 'loginsb' }),
    UfmUserTrackingModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      multi: true,
      deps: [HttpClient, KeycloakService]
    },
    CookieService,
    ProfilService,
    RejsestraekningService,
    { provide: LOCALE_ID, useValue: 'da-DK' },
    AdressePipe,
    DatePipe,
    DawaAdressePipe,
    PostnrPipe,
    LandenavnPipe,
    BsModalService,
    ApiFormService,
    { provide: BsDatepickerConfig, useFactory: getBsDatepickerConfig },
    TypeaheadConfig
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
